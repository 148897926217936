import { useState } from 'react'
import { Dropdown, Form, Spinner } from 'react-bootstrap'
import RuleRounded from '@mui/icons-material/RuleRounded'
import SpeakerNotesRounded from '@mui/icons-material/SpeakerNotesRounded'
import ExportIcon from '@mui/icons-material/FileOpenRounded'
import { ComponentState } from 'src/types/ComponentState'
import {
    AuthenticatedUser,
    Project,
    RecordingSession,
    SignalEntry,
    SignalIdentifier,
    UserBillableUnitInfo,
    VisualizeExportOption,
} from 'src/api/CloudApi/types'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { formattedToastMessage } from 'src/utils/toast'
import CloudApi from 'src/api/CloudApi'
import { PlottableSignalEntry } from '../../Types'

interface ToolbarProps {
    selfContained: boolean
    componentState: ComponentState
    setShowSelectSignalsModal: (show: boolean) => void
    isAddAnnotationActive: boolean
    setIsAddAnnotationActive: (active: boolean) => void
    plottableSignalEntriesWithoutHiddenSignals: Array<PlottableSignalEntry>
    user: AuthenticatedUser | undefined
    billableUnit: UserBillableUnitInfo | undefined
    project: Project
    recordingSession: RecordingSession
}

export default function VisualizeToolbar(props: ToolbarProps) {
    const ICON_SIZE = 14
    const [filename, setFilename] = useState('signal_export-asd')
    const [isExporting, setIsExporting] = useState(false)

    const exportSignalData = async (
        option: VisualizeExportOption,
        visibleSignals: Array<SignalEntry>,
        project: Project,
        recordingSession: RecordingSession
    ) => {
        if (visibleSignals.length > 0) {
            const signalNames: Array<SignalIdentifier> = visibleSignals.map((s) => ({
                namespace: s.namespace,
                frameName: s.frameName,
                signalName: s.name,
            }))

            try {
                setIsExporting(true)
                const res = await CloudApi.exportTimeseries(
                    project.uid,
                    recordingSession.sessionId,
                    signalNames,
                    option,
                    filename
                )
                const filePath = res.data.filePath
                const fileNameFromPath = filePath.split('/').pop()
                toast.success(
                    <div className="text-start">
                        <strong>Export successful!</strong>
                        <p>
                            File exported to Storage as <span className="lexend-bold">{fileNameFromPath}</span>.
                            <br />
                            <br />
                            <Link to={`/p/${project.uid}/files/?path=${filePath}`}>Go here</Link> to view it.
                        </p>
                    </div>,
                    {
                        autoClose: 20_000,
                    }
                )
            } catch (err: any) {
                toast.error(
                    formattedToastMessage(
                        'Error',
                        err?.response?.data ||
                            'Something went wrong when exporting signal data. Please refresh the application and try again.'
                    )
                )
            }
            setIsExporting(false)
        } else {
            toast.error(
                formattedToastMessage(
                    'No data',
                    'No signal data to export, please select some signals to export and try again. Only visible signals will be exported.'
                )
            )
        }
    }

    const toolbar = (
        visibleSignals: Array<SignalEntry>,
        project: Project,
        recordingSession: RecordingSession,
        componentState: ComponentState,
        setShowSelectSignalsModal: (show: boolean) => void,
        isAddAnnotationActive: boolean,
        setIsAddAnnotationActive: (active: boolean) => void,
        isExportEnabled: boolean,
        isSelfContained: boolean
    ) => {
        return (
            <div style={{ marginTop: -2 }} className="px-1">
                <div className="d-flex align-items-start">
                    {isSelfContained && (
                        <button
                            disabled={componentState === ComponentState.LOADING}
                            onClick={() => setShowSelectSignalsModal(true)}
                            title="Select signals to visualize in this panel"
                            className="btn remotive-btn-no-bg border-0 p-0 px-3 m-0"
                        >
                            <div className="d-flex flex-column align-items-center">
                                <RuleRounded sx={{ fontSize: ICON_SIZE }} />
                                <p className="remotive-font-xxs m-0">Select signals</p>
                            </div>
                        </button>
                    )}

                    <button
                        disabled={componentState === ComponentState.LOADING}
                        title="Add an annotation to the graph"
                        onClick={() => setIsAddAnnotationActive(!isAddAnnotationActive)}
                        className="btn remotive-btn-no-bg border-0 p-0 m-0 px-2"
                    >
                        <div
                            className={`d-flex flex-column align-items-center ${
                                isAddAnnotationActive ? 'remotive-success-60-color' : ''
                            }`}
                        >
                            <SpeakerNotesRounded sx={{ fontSize: ICON_SIZE }} />
                            <p className="remotive-font-xxs m-0">Annotate</p>
                        </div>
                    </button>

                    {isExportEnabled && (
                        <Dropdown
                            onToggle={(isOpen: boolean) => {
                                if (isOpen) {
                                    setFilename(
                                        `export-of-${visibleSignals.length}-signals-${recordingSession.sessionId}`
                                    )
                                }
                            }}
                        >
                            <Dropdown.Toggle
                                disabled={componentState === ComponentState.LOADING || isExporting}
                                title="Export signal data"
                                className="btn remotive-btn-no-bg p-0 px-2 m-0 border-0"
                            >
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    {isExporting ? (
                                        <>
                                            <Spinner size="sm" style={{ marginBottom: 3, height: 11, width: 11 }} />
                                            <p className="remotive-font-xxs m-0">Exporting...</p>
                                        </>
                                    ) : (
                                        <>
                                            <ExportIcon sx={{ fontSize: ICON_SIZE }} />
                                            <p className="remotive-font-xxs m-0">Export</p>
                                        </>
                                    )}
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="pt-0" style={{ minWidth: 200 }}>
                                <Form.Group
                                    controlId="filenameInput"
                                    className="px-2 pb-2 remotive-neutral-40-background"
                                >
                                    <Form.Label className="remotive-font-xs m-0">Filename</Form.Label>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        className="remotive-font-xs"
                                        value={filename}
                                        maxLength={500}
                                        onChange={(e) => setFilename(e.target.value)}
                                        onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
                                    />
                                </Form.Group>
                                <Dropdown.Item
                                    onClick={() =>
                                        exportSignalData(
                                            VisualizeExportOption.CSV_WIDE,
                                            visibleSignals,
                                            project,
                                            recordingSession
                                        )
                                    }
                                >
                                    <p className="remotive-font-sm m-0">As CSV - wide format</p>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        exportSignalData(
                                            VisualizeExportOption.CSV_LONG,
                                            visibleSignals,
                                            project,
                                            recordingSession
                                        )
                                    }
                                >
                                    <p className="remotive-font-sm m-0">As CSV - long format</p>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        exportSignalData(
                                            VisualizeExportOption.PARQUET,
                                            visibleSignals,
                                            project,
                                            recordingSession
                                        )
                                    }
                                >
                                    <p className="remotive-font-sm m-0">As Parquet</p>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </div>
        )
    }

    return toolbar(
        props.plottableSignalEntriesWithoutHiddenSignals.map(
            (plottableSignalEntry) => plottableSignalEntry.signalEntry
        ),
        props.project,
        props.recordingSession,
        props.componentState,
        props.setShowSelectSignalsModal,
        props.isAddAnnotationActive,
        props.setIsAddAnnotationActive,
        true,
        props.selfContained
    )
}
