export * from './epoch'
export * from './iso8061Timestamp'

export const microsToSeconds = (micros: number): number => {
    return micros / 1_000_000
}

export const microsToHoursMinutesSeconds = (micros: number): string => {
    const microsecondsInSecond = 1_000_000
    const microsecondsInMinute = 60 * microsecondsInSecond
    const microsecondsInHour = 60 * microsecondsInMinute

    const hours = Math.floor(micros / microsecondsInHour)
    const remainingMicrosAfterHours = micros % microsecondsInHour

    const minutes = Math.floor(remainingMicrosAfterHours / microsecondsInMinute)
    const remainingMicrosAfterMinutes = remainingMicrosAfterHours % microsecondsInMinute

    const seconds = remainingMicrosAfterMinutes / microsecondsInSecond // Include microseconds as fraction

    if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds.toFixed(3)}s`
    } else if (minutes > 0) {
        return `${minutes}m ${seconds.toFixed(3)}s`
    } else {
        return `${seconds.toFixed(3)}s`
    }
}
