import { Duration, ZonedDateTime } from '@js-joda/core'
import { annotationBackgroundColor, commentIconAsSvgString, RenderableAnnotation, RenderableAnnotationProps } from '.'
import { RecordingAnnotation } from '../../../../api/CloudApi/types'
import { drawCompleteHeader } from './CustomRectDrawer'
import { Chart } from 'highcharts/highstock'
import { iso8601TimestampAsZonedDateTime, zonedDateTimeToEpochInMicros } from 'src/utils/time'

export function createExperimentalRenderableAnnotation(props: RenderableAnnotationProps): RenderableAnnotation {
    const getDuration = (annotation: RecordingAnnotation | undefined): number => {
        if (annotation?.duration !== undefined && annotation.duration !== null) {
            return Duration.parse(annotation.duration).toNanos() / 1000
        }
        return 0
    }

    const zdt = iso8601TimestampAsZonedDateTime(props.annotation.timestamp)
    const epochTimestamp = zonedDateTimeToEpochInMicros(zdt)

    const isSelectedAnnotation = props.selectedAnnotation?.id === props.annotation.id
    const selectedIntervalStart = props.selectedAnnotation?.timestamp !== undefined ? epochTimestamp : 0
    const selectedIntervalStop = selectedIntervalStart + getDuration(props.selectedAnnotation)
    const myIntervalStart = epochTimestamp
    const myIntervalStop = epochTimestamp + getDuration(props.annotation)
    const isInsideSelectedInterval = selectedIntervalStart < myIntervalStart && selectedIntervalStop > myIntervalStop

    const hasDuration = (): boolean => {
        return props.annotation.duration !== undefined && props.annotation.duration !== null
    }

    return {
        onRender(chart: Chart) {
            if (getDuration(props.annotation) === 0) {
                return
            }

            const xAxis = {
                from: chart.xAxis[0].toPixels(epochTimestamp, false),
                to: chart.xAxis[0].toPixels(epochTimestamp + getDuration(props.annotation), false),
            }

            // Only draw things between pixels 24 and 42 otherwise it will be inside the graph our behind the zoom-buttons.
            // Pixels are counted from the top left (0,0) and increments to the right and down. I.e (1,1) is 1 pixel down and 1 pixel to the right
            const drawingArea = {
                upperLimit: 24,
                lowerLimit: 42,
            }
            drawCompleteHeader(
                chart,
                xAxis,
                drawingArea,
                isSelectedAnnotation,
                props.annotation,
                props.selectedAnnotation,
                props.onClick
            )
        },

        plotLines: hasDuration()
            ? [
                  {
                      value: epochTimestamp,
                      color: annotationBackgroundColor(
                          props.annotation,
                          props.selectedAnnotation,
                          props.isDarkMode,
                          '80%'
                      ),
                      dashStyle: isSelectedAnnotation ? 'ShortDash' : 'Dot',
                      width: 2,
                  },
                  {
                      value: epochTimestamp + getDuration(props.annotation),
                      color: annotationBackgroundColor(
                          props.annotation,
                          props.selectedAnnotation,
                          props.isDarkMode,
                          '80%'
                      ),
                      dashStyle: isSelectedAnnotation ? 'ShortDash' : 'Dot',
                      width: 2,
                  },
              ]
            : [
                  {
                      id: `${props.annotation.title}-${props.annotation.created}-line`,
                      value: epochTimestamp,
                      width: 1,
                      color: props.isDarkMode ? 'white' : 'black',
                      zIndex: 1,
                      events: {
                          click: function (e: any) {
                              props.onClick(props.annotation)
                          },
                      },
                  },
              ],

        plotBands: !hasDuration()
            ? undefined
            : {
                  id: `${props.annotation.title}-${props.annotation.created}-band`,
                  from: epochTimestamp,
                  to: epochTimestamp + getDuration(props.annotation),
                  zIndex: props.maxDuration - getDuration(props.annotation), // High duration, low Z
                  events: {
                      click: function (e: any) {
                          props.onClick(props.annotation)
                      },
                  },
                  color: annotationBackgroundColor(props.annotation, props.selectedAnnotation, props.isDarkMode, '10%'),
                  borderColor: annotationBackgroundColor(
                      props.annotation,
                      props.selectedAnnotation,
                      props.isDarkMode,
                      '10%'
                  ),
                  label: {
                      align: 'left',
                      verticalAlign: 'top',
                      useHTML: true,
                      style: { textOverflow: 'ellipsis' },
                      text: `
                                <div class="lexend-regular ps-1 ms-1  ${isInsideSelectedInterval ? 'd-none' : ''}">
                                    <div class="d-flex align-items-center justify-content-end flex-truncate">
                                        <p class="remotive-font-xs mb-0 me-2 text-truncate ${
                                            isSelectedAnnotation
                                                ? 'remotive-success-80-color'
                                                : 'remotive-primary-60-color'
                                        }">${props.annotation.title}</p>
                                    </div>
                                    
                                </div>`,
                  },
              },

        highchartAnnotations: hasDuration()
            ? undefined
            : {
                  id: `${props.annotation.title}-${props.annotation.created}-anno`,
                  events: {
                      click: function (e: any) {
                          props.onClick(props.annotation)
                      },
                  },
                  draggable: '',
                  labels: [
                      {
                          backgroundColor: annotationBackgroundColor(
                              props.annotation,
                              props.selectedAnnotation,
                              props.isDarkMode,
                              '80%'
                          ),
                          borderWidth: 0,
                          borderRadius: 10,
                          useHTML: true,
                          formatter: function (): string {
                              return `
                                <div class="lexend-regular text-light px-2">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <p class="remotive-font-xs mb-0 me-2">${props.annotation.title}</p>
                                        <p class="d-flex mb-0 remotive-font-xs">${
                                            props.annotation.comments.length
                                        } <span style="margin-top: -1px; margin-left: 2px">${commentIconAsSvgString(
                                  'white'
                              )}</span></p>
                                    </div>
                                    
                                </div>`
                          },
                          point: {
                              xAxis: 0,
                              yAxis: 0,
                              x: epochTimestamp,
                              y: props.findClosestYValue(), // Set the label y-value to the closest y-value of timestamp
                          },
                      },
                  ],
              },
    }
}
