import Highcharts, { Chart, normalizeTickInterval } from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import { AuthenticatedUser, RecordingAnnotation, SignalEntry } from '../../../../../api/CloudApi/types'
import { AnalyticsPanelType, GraphSettings, PanelKey, PlottableSignalEntry, SharedExtremes } from '../../Types'
import { Duration, ZonedDateTime } from '@js-joda/core'
import { useEffect, useRef, useState } from 'react'
import { AnnotationTheme, createRenderableAnnotation, RenderableAnnotationProps } from '../../RenderableAnnotation'
import { ANNOTATION_HEADER_RECT_KEY } from '../../RenderableAnnotation/CustomRectDrawer'
import LoadingContainer from '../../../../../components/LoadingContainer'
import {
    epochInMicrosToDate,
    microsToSeconds,
    epochInMicrosToZonedDateTime,
    epochInMicrosToHHmmss,
} from 'src/utils/time'
import { useDarkMode } from 'src/hooks/useDarkMode'

interface TimeSeriesChartProps {
    currentUser: AuthenticatedUser | undefined
    maxHeight: number
    panelKey: PanelKey
    plottableSignalEntriesWithoutHiddenSignals: Array<PlottableSignalEntry>
    plottableSignalEntries: Array<PlottableSignalEntry> | undefined
    availableAnnotations: Array<RecordingAnnotation> | undefined
    selectedSignals: Array<SignalEntry>
    hiddenSignals: Array<SignalEntry>
    graphSettings: GraphSettings
    selectedAnnotation: RecordingAnnotation | undefined
    isAddAnnotationActive: boolean
    sharedExtremes: SharedExtremes
    setSharedExtrems: (newExtremes: SharedExtremes) => void
    setSelectedAnnotation: (annotation: RecordingAnnotation | undefined) => void
    setShowAddAnnotationModal: (show: boolean) => void
    setLastClickedAnnotationTimestamp: (timestamp: number) => void
    setLastClickedAnnotationTimestampEnd: (timestamp: number) => void
}

enum highchartsConstructorType {
    DEFAULT_CHART = 'chart',
    STOCK_CHART = 'stockChart',
    MAP_CHART = 'mapChart',
    GANTT_CHART = 'ganttChart',
}

const GRAPH_CHART_STACKED_BASE_HEIGHT = 40
const GRAPH_CHARTS_GAP = 20
const GRAPH_CHART_STACKED_INITIAL_OFFSET = 43 - GRAPH_CHARTS_GAP

// Do not change navigator margin and height without changing this
const GRAPH_NAVIGATOR_HEIGHT_OFFSET = 33

export default function TimeSeriesChart(props: TimeSeriesChartProps) {
    const chartRef = useRef<HighchartsReact.RefObject>(null)
    const [annotationsToShowInGraph, setAnnotationsToShowInGraph] = useState<Array<RecordingAnnotation>>(
        props.availableAnnotations ?? []
    )
    const { isDarkMode } = useDarkMode()
    const [pointEvent, setPointEvent] = useState<number>()
    const [startTimestamp, setStartTimestamp] = useState<number>()

    useEffect(() => {
        if (props.plottableSignalEntries !== undefined && props.plottableSignalEntries.length > 0) {
            setStartTimestamp(props.plottableSignalEntries[0].signalData[0][0])
        }
    }, [props.plottableSignalEntries])

    useEffect(() => {
        if (props.availableAnnotations) {
            if (props.graphSettings.showOnlyMyAnnotations) {
                setAnnotationsToShowInGraph(
                    props.availableAnnotations.filter((it) => {
                        console.log(`createdBy.uid=${it.createdBy.uid} and currentUser.uid=${props.currentUser?.uid}`)
                        return it.createdBy.uid === props.currentUser?.uid
                    })
                )
            } else if (props.graphSettings.showAllAnnotations) {
                setAnnotationsToShowInGraph(props.availableAnnotations)
            } else {
                setAnnotationsToShowInGraph([])
            }
        }
    }, [props.graphSettings, props.availableAnnotations])

    const constructSignalNameKey = (signalName: string, frameName: string, namespace: string) => {
        const name = `${namespace}-${frameName}-${signalName}`.toLowerCase()
        return name
    }

    function findClosestYValueFromXValue(
        timestamp_iso: string,
        plottableSignalEntriesWithoutHiddenSignals: Array<PlottableSignalEntry>
    ): any {
        const timestamp = ZonedDateTime.parse(timestamp_iso).toInstant().toEpochMilli()

        const closestTimestamp = plottableSignalEntriesWithoutHiddenSignals
            .flatMap((it) => {
                // Flatten and return all x, y value pairs
                const closestDataPoint = it.signalData.flatMap((signalDataPoint) => {
                    return { x: signalDataPoint[0], y: signalDataPoint[1] }
                })
                return closestDataPoint
            })
            .find((dataPoint) => dataPoint.x > timestamp)
        return closestTimestamp?.y ?? 0
    }

    const storeExtremes = (e: Highcharts.AxisSetExtremesEventObject) => {
        switch (e.trigger as Highcharts.AxisExtremesTriggerValue) {
            case 'navigator':
            case 'pan':
            case 'scrollbar':
            case 'zoom':
            case 'rangeSelectorButton':
                props.setSharedExtrems({ xMax: Math.trunc(e.max), xMin: Math.trunc(e.min) })
                //syncExtremes(e)
                break

            case 'rangeSelectorInput':
            case 'traverseUpButton':
                // do nothing
                break
        }
    }

    const calculateTop = (index: number) => {
        return index * chartHeight() + (index + 1) * GRAPH_CHARTS_GAP + GRAPH_CHART_STACKED_INITIAL_OFFSET
    }

    const chartHeight = () =>
        props.plottableSignalEntriesWithoutHiddenSignals.length > 1 ? props.graphSettings.stackedChartHeight : 135

    const totalHeight = (plottableSignalEntriesWithoutHiddenSignals: number) =>
        GRAPH_CHART_STACKED_INITIAL_OFFSET +
        GRAPH_CHART_STACKED_BASE_HEIGHT +
        plottableSignalEntriesWithoutHiddenSignals * chartHeight() +
        plottableSignalEntriesWithoutHiddenSignals * GRAPH_CHARTS_GAP +
        GRAPH_NAVIGATOR_HEIGHT_OFFSET

    const getColor = (entry: PlottableSignalEntry) => {
        return Highcharts.getOptions().colors![
            props.selectedSignals.findIndex(
                (it) =>
                    constructSignalNameKey(it.name, it.frameName, it.namespace) ===
                    constructSignalNameKey(
                        entry.signalEntry.name,
                        entry.signalEntry.frameName,
                        entry.signalEntry.namespace
                    )
            )
        ]
    }

    const highchartsOptions = (
        availableAnnotations: Array<RecordingAnnotation>,
        graphSettings: GraphSettings,
        panelKey: PanelKey,
        isAddAnnotationActive: boolean,
        sharedExtremes: SharedExtremes,
        setShowAddAnnotationModal: (show: boolean) => void,
        setSelectedAnnotation: (selectedAnnotation: RecordingAnnotation | undefined) => void,
        isDarkMode: boolean
    ) => {
        const backgroundColor = isDarkMode ? '#202020' : '#fff'
        const textColor = isDarkMode ? '#fff' : '#000000'

        const series = props.plottableSignalEntriesWithoutHiddenSignals.map((entry, i) => {
            const unit = entry.signalEntry.unit
            const color = getColor(entry)
            const signalKey = constructSignalNameKey(
                entry.signalEntry.name,
                entry.signalEntry.frameName,
                entry.signalEntry.namespace
            )
            const attributes = {
                dataGrouping: {
                    enabled: false,
                },
                boostThreshold: 1000,
                id: signalKey,
                name: `${entry.signalEntry.name}`,
                data: entry.signalData,
                lineWidth: 1.0,
                marker: {
                    radius: 2, // Smaller points
                },
                selected: true,
                yAxis: i,
                color: color,
                tooltip: {
                    valueDecimals: 5,
                },
            }
            return attributes
        })

        // Get the maxDuration on the annotations and use this to get the correct Z index by reversing
        // high duration to low zIndex
        const maxDuration = Math.max(
            ...annotationsToShowInGraph
                .filter((b) => b.duration !== undefined && b.duration !== null)
                .map((band) => (Duration.parse(band.duration!).toNanos() / 1000) as number)
        )

        const renderableAnnotations = annotationsToShowInGraph.map((a) =>
            createRenderableAnnotation(AnnotationTheme.EXPERIMENTAL, {
                annotation: a,
                maxDuration: maxDuration,
                selectedAnnotation: props.selectedAnnotation,
                findClosestYValue: () =>
                    findClosestYValueFromXValue(a.timestamp, props.plottableSignalEntriesWithoutHiddenSignals),
                onClick: setSelectedAnnotation,
                isDarkMode: isDarkMode,
            } as RenderableAnnotationProps)
        )

        const isMultipleYAxes = props.plottableSignalEntriesWithoutHiddenSignals.length > 1

        const yAxis = props.plottableSignalEntriesWithoutHiddenSignals.map((entry, i) => {
            /*
             Start safe with name value options
             To Prevent that we do not render in any weird way, make sure to only manually set tickPositions when
             unique values are same or equal to the name options in the signal-db. In some cases only one value
             has an enum representation.

             A human readable value can still be shown if the auto created ticks matches.
              */
            const signalDataValues = new Set(Array.from(entry.signalData.values()).map((v) => v[1]))
            const signalDataMax = Math.max(...Array.from(signalDataValues))
            const signalDataMin = Math.min(...Array.from(signalDataValues))
            const isSignalMissingMingMax = signalDataMin === signalDataMax

            const calculateTickPositionsForNameOptions = () => {
                return undefined
                const signalNameOptionValues = entry.signalEntry.signalNameOptions.map((option) => option.value)
                const isSignalDataValuesUsingSignalNameOptionValues =
                    signalNameOptionValues?.length >= signalDataValues.size
                if (isSignalDataValuesUsingSignalNameOptionValues) {
                    const metadataOptionMax = Math.max(...signalNameOptionValues)
                    const metadataOptionMin = Math.min(...signalNameOptionValues)
                    const isSignalDataWithinRange =
                        signalDataMax <= metadataOptionMax && signalDataMin >= metadataOptionMin
                    return isSignalDataWithinRange ? signalNameOptionValues : undefined
                }
                return undefined
            }

            const calculateYAxisMin = () => {
                if (props.graphSettings.useSuggestedMinMax) {
                    return entry.signalEntry?.min
                }
                return isSignalMissingMingMax ? signalDataMin - 1 : undefined
            }

            const calculateYAxisMax = () => {
                if (props.graphSettings.useSuggestedMinMax) {
                    return entry.signalEntry?.max
                }
                return isSignalMissingMingMax ? signalDataMax + 1 : undefined
            }

            const tickPositions =
                entry.signalEntry.signalNameOptions?.length > 0 ? calculateTickPositionsForNameOptions() : undefined

            const a = {
                showLastLabel: true,
                showFirstLabel: true,
                visible: graphSettings.showYAxis,
                zoomEnabled: true,
                crosshair: true,
                tickPositions: tickPositions,
                lineWidth: 1,
                opposite: props.graphSettings.useStackedCharts ? false : i % 2 !== 0,
                labels: {
                    style: {
                        color: textColor,
                        fontSize: '10px',
                    },
                    formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
                        if (typeof this.value === 'number') {
                            if (entry.signalEntry.signalNameOptions?.length > 0) {
                                // Try convert to human readable name value option
                                const name = entry.signalEntry.signalNameOptions.find(
                                    (option) => option.value === this.value
                                )?.name
                                return name === undefined ? this.value : `${name} (${this.value})`
                            }

                            return this.value % 1 !== 0 ? this.value.toFixed(3) : this.value.toString()
                        }
                        return this.value
                    },
                    align: props.graphSettings.useStackedCharts ? 'right' : undefined,
                    reserveSpace: props.graphSettings.useStackedCharts ? true : undefined,
                    offset: -10,
                },
                softMin: calculateYAxisMin(),
                softMax: calculateYAxisMax(),
                top: props.graphSettings.useStackedCharts ? calculateTop(i) : undefined,
                height: props.graphSettings.useStackedCharts ? chartHeight() : undefined,
                resize: {
                    enabled: true,
                },
                offset: props.graphSettings.useStackedCharts ? 0 : undefined,
                title: {
                    enabled: !props.graphSettings.useStackedCharts && isMultipleYAxes ? true : false,
                    align: 'high',
                    text: entry.signalEntry.name,
                    style: {
                        color: getColor(entry),
                    },
                },
            }
            return a
        })

        const plotLines = renderableAnnotations.flatMap((a) => a.plotLines).filter((a) => a !== undefined)
        if (pointEvent) {
            plotLines.push({
                id: `selected_point`,
                dashStyle: 'ShortDot',
                value: pointEvent,
                width: 3,
                zIndex: 1,
                label: {
                    style: {
                        color: isDarkMode ? 'white' : 'black',
                    },
                    text: 'Measurement start', // Label text
                    align: 'left', // Alignment relative to the line
                    verticalAlign: 'middle',
                },
            })
        }

        const options: any = {
            credits: {
                enabled: false,
            },
            boost: {
                debug: { showSkipSummary: true },
                //usePreAllocated: true,
                //useGPUTranslations: true,
            },
            chart: {
                // BEGIN Custom attributes
                chartType: AnalyticsPanelType.SIGNAL_TIME_SERIES,
                panelKey: panelKey.key,
                // END Custom attributes
                style: {
                    fontFamily: 'LexendDecaRegular',
                },
                backgroundColor: backgroundColor,
                zooming: {
                    type: 'x',
                    mouseWheel: false,
                },
                panning: true,
                panKey: 'shift',
                zoomType: 'x',
                height: props.graphSettings.useStackedCharts
                    ? totalHeight(props.plottableSignalEntriesWithoutHiddenSignals.length)
                    : 420,
                events: {
                    render: function (this: Chart) {
                        const chart = this

                        // @ts-ignore
                        if (chart[ANNOTATION_HEADER_RECT_KEY]) {
                            // @ts-ignore
                            const allHeaders = chart[ANNOTATION_HEADER_RECT_KEY]
                            // We need to perform deletion of annotation-headers here for now.
                            // After a redraw this will destroy any annotation header that was potentially removed
                            Object.keys(allHeaders)
                                .filter((key) => !availableAnnotations.map((a) => String(a.id)).includes(key))
                                .forEach((key) => {
                                    allHeaders[key].destroy()
                                })
                        } else {
                            // @ts-ignore
                            chart[ANNOTATION_HEADER_RECT_KEY] = {}
                        }
                        renderableAnnotations.forEach((a) => a.onRender(chart))
                    },

                    selection: function (event: any) {
                        if (isAddAnnotationActive) {
                            props.setLastClickedAnnotationTimestamp(event.xAxis[0].min)
                            props.setLastClickedAnnotationTimestampEnd(event.xAxis[0].max)
                            setShowAddAnnotationModal(true)
                            return false
                        }
                        return true
                    },

                    click: function (event: any) {
                        if (isAddAnnotationActive) {
                            props.setLastClickedAnnotationTimestamp(event.xAxis[0].value)
                            setShowAddAnnotationModal(true)
                        }
                    },
                    afterUpdate: (it: Highcharts.Chart) => {
                        if (
                            chartRef.current?.chart !== undefined &&
                            props.availableAnnotations !== undefined &&
                            props.plottableSignalEntries !== undefined &&
                            props.sharedExtremes.xMin !== undefined &&
                            props.sharedExtremes.xMax !== undefined
                        ) {
                            // TODO this is the only place where we interace with the chart directly.
                            // Would be nice to be able to remove this one day and only rely on the React state changes...
                            chartRef.current.chart.xAxis[0].setExtremes(
                                props.sharedExtremes.xMin,
                                props.sharedExtremes.xMax,
                                true,
                                false,
                                { trigger: 'setExtremesIfPresentOnAfterUpdate' }
                            )
                        }
                    },
                },
            },

            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                            maxHeight: 200,
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal',
                            },
                        },
                    },
                ],
            },

            scrollbar: {
                enabled: false, // Disable the horizontal scrollbar
            },
            navigator: {
                margin: 6,
                height: 25,
                enabled: true,
                series: {
                    //color: '#000000',
                    data: [],
                },
                backgroundColor: '#f0f0f0',
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '10px',
                            color: textColor,
                        },
                        //enabled: false, // Disable x-axis labels in navigator
                        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
                            const microsecondValue = this.value as number // Ensure value is treated as a number
                            if (startTimestamp !== undefined) {
                                return `${microsToSeconds(microsecondValue - startTimestamp).toFixed(3)}s`
                            }

                            return `${epochInMicrosToHHmmss(microsecondValue)}`
                        },
                    },
                    title: {
                        text: null, // Remove x-axis title in navigator
                    },
                    tickWidth: 0,
                    lineWidth: 0,
                },
            },

            rangeSelector: {
                buttonPosition: {
                    y: -9,
                },
                buttons: [
                    {
                        type: 'second',
                        count: 5_000,
                        text: '5s',
                    },
                    {
                        type: 'second',
                        count: 10_000,
                        text: '10s',
                    },
                    {
                        type: 'second',
                        count: 30_000,
                        text: '30s',
                    },
                    {
                        type: 'all',
                        text: 'All',
                    },
                ],
                inputEnabled: false, // Disables the input fields
                selected: 5,
                buttonTheme: {
                    fill: isDarkMode ? '#1a1a1a' : '#f0f0f0', // Background color
                    style: {
                        color: isDarkMode ? '#ffffff' : '#000000', // Text color
                    },
                    states: {
                        disabled: {
                            fill: isDarkMode ? '#000' : '#d9d9d9', // Selected background
                            style: {
                                color: isDarkMode ? '#555555' : '#f0f0f0', // Background color
                            },
                        },
                        hover: {
                            fill: isDarkMode ? '#444444' : '#e6e6e6', // Hover background
                            style: {
                                color: isDarkMode ? '#ffffff' : '#000000', // Hover text color
                            },
                        },
                        select: {
                            fill: isDarkMode ? '#555555' : '#d9d9d9', // Selected background
                            style: {
                                color: isDarkMode ? '#ffffff' : '#000000', // Selected text color
                            },
                        },
                    },
                },
                labelStyle: {
                    color: isDarkMode ? '#ffffff' : '#000000', // Label text color
                },
            },

            legend: {
                enabled: false,
            },

            series: series,
            tooltip: {
                outside: true,
                backgroundColor: isDarkMode ? '#333333' : '#f9f9f9',
                style: {
                    color: textColor,
                },
                formatter: function () {
                    // 'this' refers to the hovered point
                    const xValue = (this as any).x //as any // x-axis value
                    // const xValue = this.x; // Use this if xAxis is numeric or datetime

                    const eventTs = pointEvent ? pointEvent : undefined
                    const diff = eventTs
                        ? `<hr/>Delta time: ${microsToSeconds(xValue - eventTs).toString()} seconds`
                        : '</br></br>Click a point to measure delta time'
                    const time =
                        startTimestamp !== undefined
                            ? `${microsToSeconds(xValue - startTimestamp).toFixed(6)}s`
                            : epochInMicrosToHHmmss(xValue, true)
                    const tooltipText = // `this.points` contains all points at the hovered x-value across all series
                        ((this as any).points as Highcharts.Point[])
                            .map((point) => {
                                const entry = props.plottableSignalEntriesWithoutHiddenSignals
                                    // TODO - point.series.name is not enough to make sure we match the correct signal,
                                    //       frame+signal is required
                                    .filter((e) => e.signalEntry.name === point.series?.name)
                                    .map((e) => e.signalEntry.signalNameOptions)

                                // Should only be possible to have 1 in the list
                                const result = entry[0].find((item) => item.value === point.y)

                                const seriesLabel = result ? `${result?.name} (${point.y})` : point.y
                                return `<span style="color:${point.series?.color}">\u25CF</span> <strong>${point.series?.name}:</strong> ${seriesLabel}<br/>`
                            })
                            .join('')
                    const defaultInfo = `</br>Time: ${time}</br> Timestamp: ${epochInMicrosToZonedDateTime(
                        xValue
                    ).toOffsetDateTime()}`

                    return `${tooltipText}${defaultInfo}${diff}`
                },
                useHTML: true, //
            },

            plotOptions: {
                series: {
                    animation: false,
                    point: {
                        events: {
                            click: function (event: PointerEvent) {
                                //setClickedPoint(this)
                                // Optional: Additional actions on click
                                console.log('Point clicked:', event)

                                const xVal = (this as any).category || (this as any).x
                                console.log(xVal)
                                //const yVal = this.y
                                //const seriesName = this.series.name
                                setPointEvent(xVal)
                            },
                        },
                    },
                },
                flags: {
                    accessibility: {
                        exposeAsGroupOnly: true,
                        description: 'Flagged events.',
                    },
                },
                line: {
                    marker: {
                        enabled: true,
                    },
                },
            },
            yAxis: yAxis,
            xAxis: {
                labels: {
                    style: {
                        fontSize: '10px',
                        color: textColor,
                    },
                    formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
                        const microsecondValue = this.value as number // Ensure value is treated as a number
                        if (startTimestamp !== undefined) {
                            return `${microsToSeconds(microsecondValue - startTimestamp).toFixed(3)}s`
                        }

                        return `${epochInMicrosToHHmmss(microsecondValue)}`
                    },
                },
                min: sharedExtremes.xMin,
                max: sharedExtremes.xMax,
                visible: graphSettings.showXAxis,
                type: 'datetime',
                ordinal: false,
                crosshair: {
                    snap: false,
                },
                events: {
                    setExtremes: (event: Highcharts.AxisSetExtremesEventObject) => {
                        // Do nothing
                    },
                    afterSetExtremes: storeExtremes,
                },

                plotBands: renderableAnnotations.map((a) => a.plotBands).filter((a) => a !== undefined),

                plotLines: plotLines,
            },
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        symbolStroke: isDarkMode ? '#ffffff' : '#000000', // Icon color
                        theme: {
                            fill: isDarkMode ? '#333333' : '#ffffff', // Button background
                            stroke: isDarkMode ? '#444444' : '#e6e6e6', // Button border
                            style: {
                                color: isDarkMode ? '#ffffff' : '#000000', // Text color
                            },
                            states: {
                                hover: {
                                    fill: isDarkMode ? '#444444' : '#f0f0f0', // Hover background
                                    style: {
                                        color: isDarkMode ? '#ffffff' : '#000000', // Hover text color
                                    },
                                },
                            },
                        },
                    },
                },
                menuItemStyle: {
                    background: isDarkMode ? '#333333' : '#ffffff', // Menu item background
                    color: isDarkMode ? '#ffffff' : '#000000', // Menu item text color
                    padding: '8px 16px', // Adjust padding as needed
                    border: 'none', // Optional: Remove borders
                },
                menuItemHoverStyle: {
                    background: isDarkMode ? '#444444' : '#f0f0f0', // Hover background
                    color: isDarkMode ? '#ffffff' : '#000000', // Hover text color
                },
                menuStyle: {
                    background: isDarkMode ? '#333333' : '#ffffff', // Menu container background
                    color: isDarkMode ? '#ffffff' : '#000000', // Menu container text color
                    border: `1px solid ${isDarkMode ? '#444444' : '#e6e6e6'}`, // Menu container border
                    boxShadow: isDarkMode
                        ? '0 2px 4px rgba(0, 0, 0, 0.6)' // Darker shadow for dark mode
                        : '0 2px 4px rgba(0, 0, 0, 0.1)', // Lighter shadow for light mode
                    borderRadius: '4px', // Optional: Rounded corners
                },
            },

            annotations: renderableAnnotations.map((a) => a.highchartAnnotations).filter((a) => a !== undefined),
        }

        return options
    }

    const higchartsGraphOrEmpty = () => {
        if (props.selectedSignals.length > 0 && props.selectedSignals.length === props.hiddenSignals.length) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <p className="m-0 remotive-font-xs">All signals are hidden</p>
                </div>
            )
        }
        if (
            (props.selectedSignals.length > 0 && props.plottableSignalEntries === undefined) ||
            props.availableAnnotations === undefined
        ) {
            return <LoadingContainer loadingText="" infoText="Plotting signal data..." spinnerSize="sm" />
        }
        if (props.plottableSignalEntries === undefined || props.availableAnnotations === undefined) {
            return <></>
        }
        return (
            <HighchartsReact
                data-ANALYTICS_CHART_TYPE_KEY={AnalyticsPanelType.SIGNAL_TIME_SERIES}
                highcharts={Highcharts}
                options={highchartsOptions(
                    props.availableAnnotations,
                    props.graphSettings,
                    props.panelKey,
                    props.isAddAnnotationActive,
                    props.sharedExtremes,
                    props.setShowAddAnnotationModal,
                    props.setSelectedAnnotation,
                    isDarkMode
                )}
                constructorType={highchartsConstructorType.STOCK_CHART}
                className="chart"
                updateArgs={[true, true, false]} //redraw, oneToOne, Animation
                ref={chartRef}
            />
        )
    }

    //console.log(`Rendering due to state change in ${props.panelKey.key}`)

    return (
        <div
            className={`${props.selectedAnnotation === undefined ? 'col-12' : 'col-6 col-md-8 col-xxl-9 pe-1'}`}
            style={{}}
            id={`${props.panelKey.key}-graph`}
        >
            {higchartsGraphOrEmpty()}
        </div>
    )
}
