import { Form } from 'react-bootstrap'
import { FrameEntry, SignalEntry } from '../../../../api/CloudApi/types'

interface SignalItemProps {
    className?: string
    isFrame: boolean
    parentFrameEntry: FrameEntry
    thisSignalEntry: SignalEntry
    selectedSignals: Array<SignalEntry>
    addSelectedSignalFunction: (signal: SignalEntry) => void
    removeSelectedSignalFunction: (signal: SignalEntry) => void
    isDisabled: boolean
}

export default function SignalItem(props: SignalItemProps) {
    const selectSignalOnChange = (event: any, targetedSignal: SignalEntry) => {
        if (event.target.checked) {
            props.addSelectedSignalFunction(targetedSignal)
        } else {
            props.removeSelectedSignalFunction(targetedSignal)
        }
    }

    const signalItem = (signalEntry: SignalEntry) => {
        const isChecked = props.selectedSignals.some(
            (it) => it.name === signalEntry.name && it.namespace === signalEntry.namespace
        )
        const remotiveColor = props.isDisabled
            ? 'remotive-neutral-40-background'
            : props.isFrame
            ? 'remotive-primary-20-background'
            : 'remotive-primary-10-background'
        return (
            <div
                key={`${signalEntry.namespace}-${signalEntry.frameName}-${signalEntry.name}-signal-item`}
                className={`rounded mt-1 ${remotiveColor} w-100 ${props.className}`}
            >
                <fieldset disabled={props.isDisabled}>
                    <div className="row text-break lexend-regular align-middle p-0 m-0">
                        <div className="col-6">
                            <div style={{ paddingTop: 2, paddingBottom: 2 }} className="d-flex align-items-center">
                                <Form.Check
                                    id={`${signalEntry.namespace}-${signalEntry.frameName}-${signalEntry.name}-signal-item`}
                                    type={'checkbox'}
                                    value={JSON.stringify(signalEntry)}
                                    checked={isChecked}
                                    onChange={(e) => selectSignalOnChange(e, signalEntry)}
                                />
                                <label
                                    htmlFor={`${signalEntry.namespace}-${signalEntry.frameName}-${signalEntry.name}-signal-item`}
                                    className={`form-check-label text-wrap remotive-font-sm ms-2 ${
                                        props.isFrame ? 'lexend-bold' : ''
                                    }`}
                                >
                                    {signalEntry.name} {props.isFrame && <span className='ms-2 lexend-regular remotive-secondary-color'>{signalEntry.namespace}</span>}
                                </label>
                            </div>
                        </div>
                        <div className="col-3 remotive-font-sm d-flex align-items-center">
                            <p className="remotive-secondary-color ps-1 m-0">{`${signalEntry.unit ?? 'unitless'}`}</p>
                        </div>
                        <div className="col-3 remotive-font-sm d-flex align-items-center">
                            <p className="remotive-secondary-color ps-1 m-0">
                                {signalEntry.min === undefined || signalEntry.max
                                    ? ''
                                    : `${signalEntry.min} - ${signalEntry.max}`}
                            </p>
                        </div>
                        {props.isFrame && (
                            <div className="col-12 mb-1">
                                <div className="d-flex flex-row align-items-end lh-1">
                                    <p title="Senders" className="m-0 remotive-font-xs remotive-secondary-color">
                                        {props.parentFrameEntry.senders
                                            ? props.parentFrameEntry.senders.map((it, index) =>
                                                  index === props.parentFrameEntry.senders.length - 1 ? it : `${it}, `
                                              )
                                            : 'N/A'}
                                    </p>
                                    <p
                                        title="Receivers"
                                        className="m-0 ms-4 remotive-font-xs remotive-secondary-color"
                                    >
                                        {props.parentFrameEntry.receivers
                                            ? props.parentFrameEntry.receivers.map((it, index) =>
                                                  index === props.parentFrameEntry.receivers.length - 1 ? it : `${it}, `
                                              )
                                            : 'N/A'}
                                    </p>
                                    <p
                                        title="Cycle Time"
                                        className="m-0 ms-4 remotive-font-xs remotive-primary-70-color"
                                    >
                                        {props.parentFrameEntry.cycleTime
                                            ? `${props.parentFrameEntry.cycleTime}ms`
                                            : 'N/A'}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="col-12">
                            {signalEntry.comments !== null && (
                                <p className="remotive-font-sm remotive-secondary-color mt-0 pb-0 mb-1 py-0 ms-0 text-wrap">
                                    {signalEntry.comments}
                                </p>
                            )}
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    }

    return signalItem(props.thisSignalEntry)
}
