import { useEffect, useRef, useState } from 'react'
import { Alert, Container } from 'react-bootstrap'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import {
    AuthenticatedUser,
    FileObject,
    FileObjectsContainer,
    FileObjectTypeEnum,
    Project,
    UserBillableUnitInfo,
} from '../../api/CloudApi/types'
import { BreadCrumb, BreadCrumbs, buAndProjectTrail } from '../../types/BreadCrumbs'
import { Link, useSearchParams } from 'react-router-dom'
import NavigationBar from '../../components/navigation/NavigationBar'
import { CURRENT_PATH_PARAM } from '../../utils/queryParams'
import RLCard from '../../components/cards/RLCard'
import { PageDetails } from '../../utils/pageDetails'
import { hasPermission, Permission } from '../../utils/permission'
import CloudApi from '../../api/CloudApi'
import { ComponentState } from '../../types/ComponentState'
import LoadingContainer from '../../components/LoadingContainer'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../utils/toast'
import ErrorContainer from '../../components/ErrorContainer'
import NotFoundContaner from '../../components/NotFoundContainer'
import CliHintContainer, { CliHint } from '../../components/CliHintContainer'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'
import { ProductAnalyticsContext, ProductAnalyticsProperties } from '../../utils/ProductAnalytics'
import CreateFolderModal from './CreateFolderModal'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import UploadQueue from './utilities/UploadQueue'
import MultipleInlineFileUploadProgressContainer from '../../components/MultipleFileUploadProgressContainer'
import FileDetail from './FileDetail'
import {
    CloudUploadIcon,
    CreateFolderIcon,
    GridViewIcon,
    ListViewIcon,
    RefreshIcon,
    StorageIcon,
    UploadIcon,
} from 'src/assets/Icons'
import FilesList from './FilesList'

interface FilesProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentUser: AuthenticatedUser | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export type UploadInProgress = {
    rate: number | undefined
    fileName: string
    file: File
    percent: number
    error: string | undefined
    abortController: AbortController
}

export enum LayoutType {
    LIST = 'LIST',
    CARDS = 'CARDS',
}

export enum SortingType {
    UPLOADED_ASC = 'Uploaded (Oldest)',
    UPLOADED_DESC = 'Uploaded (Newest)',
    NAME_ASC = 'Name (A to Z)',
    NAME_DESC = 'Name (Z to A)',
    SIZE_ASC = 'Size (Smallest)',
    SIZE_DESC = 'Size (Largest)',
}

const PAGE_TITLE = 'Storage'

export default function Files(props: FilesProps) {
    const dropzone = useRef<DropzoneRef | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [componentState, setComponentState] = useState(ComponentState.LOADING)
    const [currentLayoutType, setLayoutType] = useState<LayoutType>(LayoutType.LIST)
    const [fileObjectsContainer, setFileObjectsContainer] = useState<FileObjectsContainer | undefined>(undefined)

    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
    const [showBanner, setShowBanner] = useState<boolean>(false)
    const [uploads, setUploads] = useState<Array<UploadInProgress>>([])
    const uploadQueue = new UploadQueue(3)
    const [isHoveringDropzone, setIsHoveringDropzone] = useState(false)

    const [allSelected, setAllSelected] = useState(false)
    const [selectedFileObjects, setSelectedFileObjects] = useState(new Set<FileObject>())
    const [sortingType, setSortingType] = useState<SortingType>(SortingType.UPLOADED_ASC)
    const [searchKey, setSearchKey] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (props.currentProject?.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Storage - ${props.currentProject?.displayName}`,
                productAnalyticsEventName: 'Project Storage',
            })
        }
    }, [props.currentProject])

    useEffect(() => {
        const initializePage = async (project: Project) => {
            const pathFromQuery = searchParams.get(CURRENT_PATH_PARAM)
            if (pathFromQuery !== null) {
                console.debug(
                    `Found a path in query params, will use that as starting directory. Path is=${pathFromQuery}`
                )
                listFiles(project, pathFromQuery)
            } else {
                console.debug(`No path in query, will fetch root directory`)
                listFiles(project, '/')
            }
        }

        if (props.currentProject !== undefined && searchParams !== undefined) {
            initializePage(props.currentProject!)
        }
    }, [props.currentProject, searchParams])

    const showFilePickerDialog = () => {
        if (dropzone.current) {
            dropzone.current.open()
        }
    }

    const listFiles = (project: Project, path?: string) => {
        setSelectedFileObjects(new Set())
        setAllSelected(false)
        const list = async () => {
            try {
                setComponentState(ComponentState.LOADING)
                const response = await CloudApi.listStorageFilesInPath(project, path)

                setFileObjectsContainer(response.data)
                setComponentState(ComponentState.DONE)
            } catch (e: any) {
                if (e.response.status === 404) {
                    // When the last file in a directory is removed, the directory does no longer exist.
                    // For now lets go to root when this happens.
                    if (path !== '/') {
                        listFiles(project, '/')
                    } else {
                        console.log('404 at / - This probably means that there are no files uploaded')
                        setFileObjectsContainer(undefined)
                        setComponentState(ComponentState.DONE)
                    }
                } else {
                    console.error(e)
                    toast.error(
                        formattedToastMessage(
                            'File error',
                            'Failed to list files in current directory, please try again later.'
                        )
                    )
                    setComponentState(ComponentState.DONE)
                }
            }
        }
        list()
    }

    const createFolderInStorage = async (fileObjectsContainer: FileObjectsContainer, folderName: string) => {
        try {
            setComponentState(ComponentState.LOADING)
            await CloudApi.storageCreateFolder(props.currentProject!.uid, fileObjectsContainer.path, folderName)
            listFiles(props.currentProject!, fileObjectsContainer.path)
        } catch (err: any) {
            if (err.response && err.response.status === 409) {
                toast.error('File already exist')
            } else {
                toast.error('Unexpected error')
            }
            setComponentState(ComponentState.DONE)
        }
    }

    const updateUploadsInProgress = (newValue: UploadInProgress) => {
        setUploads((prevItems) =>
            prevItems.map((item) => (item.fileName === newValue.fileName ? { ...item, ...newValue } : item))
        )
    }

    const uploadFilesAsync = async (files: Array<File>, fileObjectsContainer: FileObjectsContainer) => {
        setIsHoveringDropzone(false)
        const newUploads = files.map((fileItem) => {
            const upload: UploadInProgress = {
                fileName: fileItem.name,
                file: fileItem,
                percent: 0,
                rate: undefined,
                error: undefined,
                abortController: new AbortController(),
            }
            return upload
        })
        setUploads((prev) => [...prev, ...newUploads])
        newUploads.forEach((u) => {
            uploadQueue.enqueue(() => uploadSingleFileAsync(u, fileObjectsContainer))
        })
    }

    const uploadSingleFileAsync = async (
        uploadInProgress: UploadInProgress,
        fileObjectsContainer: FileObjectsContainer
    ) => {
        const pathWhenStarting = fileObjectsContainer.path
        updateUploadsInProgress(uploadInProgress)
        const config = {
            onUploadProgress: function (progressEvent: AxiosProgressEvent) {
                uploadInProgress.percent = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                uploadInProgress.rate = progressEvent.rate
                updateUploadsInProgress(uploadInProgress)
            },
            signal: uploadInProgress.abortController.signal,
        } as AxiosRequestConfig<EventTarget>
        try {
            await CloudApi.storageUpload(props.currentProject!.uid, pathWhenStarting, uploadInProgress.file, config)

            if (pathWhenStarting === fileObjectsContainer.path) {
                listFiles(props.currentProject!, fileObjectsContainer.path)
            }
        } catch (err: any) {
            if (err.response && err.response.status === 409) {
                toast.error(`File ${uploadInProgress.fileName} already exist`)
                uploadInProgress.error = `File ${uploadInProgress.fileName} already exist`
                setComponentState(ComponentState.DONE)
            } else {
                uploadInProgress.error = `Unexpected Error`
            }
        }
        setUploads((prev) => prev.filter((item) => item.fileName !== uploadInProgress.fileName))
    }

    const isCurrentPathAFile = () => {
        return fileObjectsContainer !== undefined && fileObjectsContainer.type === FileObjectTypeEnum.FILE
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center text-truncate">
                    <div className="d-flex align-items-center remotive-primary-100-color">
                        <StorageIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 lexend-light text-truncate m-0">{PAGE_TITLE}</p>
                    </div>

                    <div>
                        {!isCurrentPathAFile() && (
                            /*Do not show upload or create folder when on a file node */
                            <>
                                <button
                                    className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1 thin-white-border-for-dark-mode"
                                    disabled={
                                        !hasPermission(
                                            Permission.PROJECT_EDITOR_CONFIG,
                                            props.currentBillableUnit,
                                            props.currentProject
                                        )
                                    }
                                    title={'Create folder'}
                                    onClick={() => setShowCreateFolderModal(true)}
                                >
                                    <CreateFolderIcon
                                        style={{ marginBottom: '3px', padding: '1px' }}
                                        sx={{ fontSize: 18 }}
                                    />
                                </button>
                                <button
                                    className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1 thin-white-border-for-dark-mode"
                                    disabled={
                                        !hasPermission(
                                            Permission.PROJECT_EDITOR_CONFIG,
                                            props.currentBillableUnit,
                                            props.currentProject
                                        )
                                    }
                                    title={'Upload files'}
                                    onClick={showFilePickerDialog}
                                >
                                    <UploadIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                                </button>
                            </>
                        )}
                        {props.currentProject !== undefined && fileObjectsContainer !== undefined && (
                            <button
                                className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary thin-white-border-for-dark-mode"
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_VIEWER_CONFIG,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    )
                                }
                                title={'Reload'}
                                onClick={() => {
                                    listFiles(props.currentProject!, fileObjectsContainer.path)
                                }}
                            >
                                <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                            </button>
                        )}
                    </div>
                </div>
            </>
        )
    }

    const noFilesInPathWarning = () => {
        return (
            <>
                <NotFoundContaner showIcon infoText="We couldn't find any files..." secondaryText="Upload some files first" />
            </>
        )
    }

    const clickResource = (path: string) => {
        searchParams.set(CURRENT_PATH_PARAM, path)
        setSearchParams(searchParams)
    }

    const renderFileObjectPath = (fileObjectsContainer: FileObjectsContainer | undefined) => {
        if (!props.currentProject || fileObjectsContainer === undefined || fileObjectsContainer.path === undefined) {
            return <></>
        }
        const isCurrentFolderOrFile = (index: number, sourceArray: string[]) => {
            return index === sourceArray.length - 1
        }

        const path = ['/', ...fileObjectsContainer.path.split('/').slice(1)]
        const includeFilename = fileObjectsContainer.type === FileObjectTypeEnum.FILE
        return (
            <div className="d-flex justify-content-start mt-2 mx-3">
                <div className="remotive-font-md m-0 d-flex flex-wrap">
                    {path.slice(0, path.length - (includeFilename ? 0 : 1)).map((it, index, sourceArray) => (
                        <div key={it} className="me-1">
                            <p className="m-0 text-start text-break">
                                {isCurrentFolderOrFile(index, sourceArray) ? (
                                    /* No link on current folder or file in breadcrumb */
                                    <>
                                        <span className={'lexend-bold'}>{it}</span>
                                        {isCurrentPathAFile() ? '' : ' >'}
                                    </>
                                ) : (
                                    <>
                                        <span
                                            className={'clickable-underlined-on-hover remotive-secondary-color'}
                                            onClick={() =>
                                                clickResource(
                                                    sourceArray
                                                        .slice(0, index + 1)
                                                        .join('/')
                                                        .substring(1) + '/'
                                                )
                                            }
                                        >
                                            {it}
                                        </span>
                                        {' >'}
                                    </>
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const renderPathHeader = (
        fileObjectsContainer: FileObjectsContainer | undefined,
        currentLayoutType: LayoutType
    ) => {
        return (
            <div className="d-flex flex-row justify-content-between">
                <div>{renderFileObjectPath(fileObjectsContainer)}</div>
                {isCurrentPathAFile() ? (
                    <></>
                ) : (
                    <div className="me-2 ">
                        <button
                            onClick={() => setLayoutType(LayoutType.LIST)}
                            className={`btn remotive-btn-tab ${
                                currentLayoutType === LayoutType.LIST
                                    ? 'remotive-dark-color'
                                    : 'remotive-primary-40-color'
                            } p-0`}
                        >
                            <ListViewIcon sx={{ fontSize: 20 }} />
                        </button>
                        <button
                            onClick={() => setLayoutType(LayoutType.CARDS)}
                            className={`btn remotive-btn-tab ${
                                currentLayoutType === LayoutType.CARDS
                                    ? 'remotive-dark-color'
                                    : 'remotive-primary-40-color'
                            } p-1`}
                        >
                            <GridViewIcon sx={{ fontSize: 20 }} />
                        </button>
                    </div>
                )}
            </div>
        )
    }

    const renderFileObjectsContainer = (fileObjectsContainer: FileObjectsContainer | undefined, project: Project) => {
        return (
            <>
                {renderPathHeader(fileObjectsContainer, currentLayoutType)}
                {renderContentBasedOnPath(fileObjectsContainer, project)}
            </>
        )
    }

    const renderContentBasedOnPath = (fileObjectsContainer: FileObjectsContainer | undefined, project: Project) => {
        if (fileObjectsContainer === undefined) {
            return noFilesInPathWarning()
        }
        if (isCurrentPathAFile() && props.currentProject !== undefined) {
            return (
                <FileDetail
                    listFilesFunction={(path: string) => listFiles(props.currentProject!, path)}
                    project={props.currentProject}
                    fileObjectsContainer={fileObjectsContainer}
                />
            )
        }
        return (
            <FilesList
                fileObjectsContainer={fileObjectsContainer}
                currentLayoutType={currentLayoutType}
                project={project}
                openConfirmationDialog={props.openConfirmationDialog}
                selectedFileObjects={selectedFileObjects}
                setSelectedFileObjects={setSelectedFileObjects}
                setComponentState={setComponentState}
                listFiles={(path: string) => listFiles(project, path)}
                setShowBanner={setShowBanner}
                clickResource={clickResource}
                isAllSelected={allSelected}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                sortingType={sortingType}
                setSortingType={setSortingType}
            />
        )
    }

    const getBody = (project: Project) => {
        switch (componentState) {
            case ComponentState.LOADING:
                return (
                    <>
                        {renderPathHeader(fileObjectsContainer, currentLayoutType)}
                        <div className="d-flex flex-column flex-grow-1 justify-content-between">
                            <div className="d-flex justify-content-center align-items-center mx-2 mt-1">
                                <LoadingContainer spinnerSize="lg" loadingText=" " />
                            </div>
                            <div className={'w-100'}>
                                {fileObjectsContainer && !isCurrentPathAFile() && (
                                    <div className={'m-2'}>{storageDropZone(fileObjectsContainer)}</div>
                                )}
                            </div>
                        </div>
                    </>
                )

            case ComponentState.ERROR:
                return (
                    <>
                        <ErrorContainer />
                    </>
                )

            case ComponentState.DONE:
            case ComponentState.DEFAULT:
                return (
                    <div className={'d-flex flex-column flex-grow-1 justify-content-between h-100'}>
                        <div>{renderFileObjectsContainer(fileObjectsContainer, project)}</div>
                        {fileObjectsContainer && !isCurrentPathAFile() && (
                            <div className={'m-2'}>{storageDropZone(fileObjectsContainer)}</div>
                        )}
                    </div>
                )
            default:
                return <></>
        }
    }

    const breadCrumbs = () => {
        const organisation = props.currentBillableUnit?.organisation
        const project = props.currentProject
        const trail = buAndProjectTrail(organisation, project)
        return {
            trail: trail,
            current: {
                title: PAGE_TITLE,
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    const cliHints = (fileObjectsContainer: FileObjectsContainer) => {
        const hints: Array<CliHint> = []

        if (!isCurrentPathAFile()) {
            hints.push({
                title: `Copy file to storage directory`,
                command: `remotive cloud storage cp myfile.txt "rcs:/${fileObjectsContainer.path}"  --project ${props.currentProject?.uid}`,
            })

            hints.push({
                title: `Copy file to remote storage but change filename`,
                command: `remotive cloud storage cp myfile.txt "rcs:/${fileObjectsContainer.path}myfile2.txt"  --project ${props.currentProject?.uid}`,
            })

            hints.push({
                title: `List files in a directory`,
                command: `remotive cloud storage ls "rcs:/${fileObjectsContainer.path}" --project ${props.currentProject?.uid}`,
            })
        } else {
            hints.push({
                title: `Copy file from remote storage`,
                command: `remotive cloud storage cp "rcs:/${fileObjectsContainer.path}" .  --project ${props.currentProject?.uid}`,
            })

            hints.push({
                title: `Copy file from remote storage but change filename`,
                command: `remotive cloud storage cp "rcs:/${fileObjectsContainer.path}" myfile2.txt  --project ${props.currentProject?.uid}`,
            })

            hints.push({
                title: `Delete remote file`,
                command: `remotive cloud storage rm "rcs:/${fileObjectsContainer.path}" --project ${props.currentProject?.uid}`,
            })
        }

        return hints
    }

    const storageDropZone = (fileObjectsContainer: FileObjectsContainer) => {
        if (hasPermission(Permission.PROJECT_EDITOR_CONFIG, props.currentBillableUnit, props.currentProject)) {
            return (
                <>
                    <div className="d-flex" style={{ height: '47px !important'}}>
                        <Dropzone
                            ref={dropzone}
                            //disabled={uploads.length > 0}
                            multiple={true}
                            onDrop={(acceptedFiles) => uploadFilesAsync(acceptedFiles, fileObjectsContainer)}
                            onDragEnter={() => {
                                setIsHoveringDropzone(true)
                            }}
                            onDragLeave={() => {
                                setIsHoveringDropzone(false)
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    className={`dropzone rounded-2 ${
                                        isHoveringDropzone
                                            ? 'remotive-primary-10-background'
                                            : 'remotive-primary-0-background'
                                    }`}
                                    style={{
                                        height: '94px',
                                    }}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                                        <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} /> Drag a file here or
                                        click to <b>upload</b>
                                    </p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    {uploads.length > 0 && (
                        <div className={'my-2'}>
                            <>
                                {uploads.map((upload) => (
                                    <div key={upload.fileName} className={'w-100'}>
                                        <MultipleInlineFileUploadProgressContainer
                                            inProgressText={
                                                upload.percent === 0 ? `Queued ${upload.fileName}` : `Uploading ${upload.fileName}`
                                            }
                                            finishedText="Upload complete, finishing up..."
                                            uploadInProgress={upload}
                                        />
                                    </div>
                                ))}
                            </>
                        </div>
                    )}
                </>
            )
        }
        return <></>
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                isDemoGuideActive={false}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>

                <Alert
                    show={showBanner}
                    onClose={() => setShowBanner(false)}
                    dismissible={true}
                    className="border-0 d-flex p-0 flex-column rounded remotive-primary-20-background my-3"
                >
                    <div className="p-3 px-4">
                        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                            <p style={{ zIndex: 10 }} className="m-0 remotive-font-sm remotive-primary-80-color">
                                {
                                    <>
                                        Import started, track progress under
                                        <Link to={`/p/${props.currentProject?.uid}/recordings`}> recordings</Link>
                                    </>
                                }
                            </p>
                        </div>
                    </div>
                </Alert>
                <div className="mt-0">{props.currentProject && <RLCard body={getBody(props.currentProject)} />}</div>
                {fileObjectsContainer && (
                    <CliHintContainer
                        topMargin={'mt-5'}
                        productAnalyticsProperties={
                            {
                                productAnalyticsContext: ProductAnalyticsContext.STORAGE,
                                currentBillableUnit: props.currentBillableUnit,
                                currentUser: props.currentUser,
                            } as ProductAnalyticsProperties
                        }
                        hints={cliHints(fileObjectsContainer)}
                    />
                )}
            </Container>
            {fileObjectsContainer && (
                <CreateFolderModal
                    show={showCreateFolderModal}
                    text={''}
                    title={''}
                    handleCloseFunction={() => setShowCreateFolderModal(false)}
                    createFolderFunction={(folderName: string) =>
                        createFolderInStorage(fileObjectsContainer, folderName)
                    }
                />
            )}
        </div>
    )
}
