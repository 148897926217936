import { FileObject } from 'src/api/CloudApi/types'
import SearchAndSortBar from './SearchAndSortBar'
import { ActionBar } from './ActionBar'
import { SortingType } from '..'

export interface ToolbarProps {
    selectedFiles: Set<FileObject>
    onSelectAll: () => void
    onReset: Function
    onDeleteSelected: Function
    onDownloadSelected: Function
    setSortingType: (type: SortingType) => void
    sortingType: SortingType
    setSearchKey: (key: string | undefined) => void
    searchKey: string | undefined
    searchStatus: string | undefined
}

export default function Toolbar(props: ToolbarProps) {
    const component = () => {
        return (
            <>
                <div className="d-flex flex-row flex-wrap">
                    <div className="me-2">
                        <ActionBar
                            selectedFiles={props.selectedFiles}
                            onSelectAll={props.onSelectAll}
                            onReset={props.onReset}
                            onDeleteSelected={props.onDeleteSelected}
                            onDownloadSelected={props.onDownloadSelected}
                        />
                    </div>
                    <div className="flex-grow-1 py-0 my-0">
                        <SearchAndSortBar
                            setSortingType={props.setSortingType}
                            sortingType={props.sortingType}
                            setSearchKey={props.setSearchKey}
                            searchKey={props.searchKey}
                            searchStatus={props.searchStatus}
                        />
                    </div>
                </div>
            </>
        )
    }

    return component()
}
