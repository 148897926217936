// DataGrid.jsx
import React, { useEffect, useRef, useState } from 'react'
import { ProcessCellForExportParams, LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

import { PlottableSignalEntry, SharedExtremes } from '../../Types'
import { FrameList, FrameMetadata, SignalEntry } from '../../../../../api/CloudApi/types'
import { tableHeaders } from './TableHeaders'
import { FrameMetadataDict, GridRow, SignalMetadataDict } from './types'
import { useDarkMode } from 'src/hooks/useDarkMode'

LicenseManager.setLicenseKey(
    '[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-074442}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{28 February 2025}____[v3]_[0102]_MTc0MDcwMDgwMDAwMA==bb2688d270ed69f72a8ba59760c71424'
)

interface DataGridProps {
    frames: FrameList | undefined
    hiddenSignals: Array<SignalEntry>
    sharedExtremes: SharedExtremes
    isExpanded: boolean
    addSignalFunc: (row: GridRow) => void
    signalsInTimeseriesChart: Array<PlottableSignalEntry>
}

const FrameGrid = (props: DataGridProps) => {
    const gridApiRef = useRef<any>(null) // Refer

    const { isDarkMode } = useDarkMode()

    const [rowData, setRowData] = useState<Array<GridRow>>([])
    //const [metadata, setMetadata] = useState<FrameMetadataDict>()
    const [startTimestamp, setStartTimestamp] = useState(0)
    const onGridReady = (params: any) => {
        console.log('Grid ready')
        gridApiRef.current = params.api // Save the API reference
    }

    const ToggleButtonRenderer = (props: any) => {
        const toggle = () => {
            const row = props.data as GridRow
            const isNotInChart =
                props.signalsInTimeseriesChart.filter((s: any) => s.signalEntry.frameSignalId !== row.frameSignalId)
                    .length < props.signalsInTimeseriesChart.length
            // Toggle the value
            const newValue = !props.value
            // Notify the grid to update the value
            props.setValue(isNotInChart)
            console.log(newValue)
        }

        return <button onClick={toggle}>{props.value ? 'ON' : 'OFF'}</button>
    }

    function convertFramesToDict(frames: FrameMetadata[]): FrameMetadataDict {
        const frameDict: FrameMetadataDict = {}

        frames.forEach((frame) => {
            const { frameNsId, signals, ...rest } = frame

            const signalsDict: SignalMetadataDict = {}
            signals.forEach((signal) => {
                signalsDict[signal.frameSignalId] = signal
            })

            frameDict[frameNsId] = {
                frameNsId,
                ...rest,
                signals: signalsDict,
            }
        })

        return frameDict
    }

    useEffect(() => {
        if (gridApiRef.current) {
            if (props.isExpanded) {
                gridApiRef.current.collapseAll() // Collapse all rows
            } else {
                gridApiRef.current.expandAll() // Expand all rows
            }
        }
    }, [props.isExpanded])

    useEffect(() => {
        applyFilter()
    }, [props.sharedExtremes])

    const applyFilter = () => {
        if (!gridApiRef.current) return

        const filterModel: any = {}

        if (props.sharedExtremes.xMin !== undefined || props.sharedExtremes.xMax !== undefined) {
            filterModel['timestamp'] = {
                type: 'inRange',
                // Seems like the Inclusive does not work properly,
                filter: props.sharedExtremes.xMin! - 1,
                filterTo: props.sharedExtremes.xMax! + 1,
                inRangeInclusive: true,
            }
        }

        gridApiRef.current.setFilterModel(filterModel)
    }

    useEffect(() => {
        if (props.frames && props.frames.metadata.length === 0) {
            setRowData([])
        }
        let metadata1: FrameMetadataDict | undefined = undefined
        if (props.frames?.metadata && props.frames.metadata.length > 0) {
            const converted = convertFramesToDict(props.frames.metadata)
            metadata1 = converted
        } else {
            return
        }
        const metadata = metadata1!
        if (props.frames && props.frames.metadata.length > 0) {
            const xMin = props.sharedExtremes.xMin ? props.sharedExtremes.xMin : 0
            const xMax = props.sharedExtremes.xMax ? props.sharedExtremes.xMax : Number.MAX_VALUE
            const startTs = props.frames.signals[0].timestamp
            setStartTimestamp(startTs)
            const data = props.frames.signals.flatMap((frame) => [
                {
                    timestamp: frame.timestamp,
                    relativeTime: frame.timestamp - startTs,
                    type: 'frame',
                    frameName: metadata![frame.frameNsId].name,
                    //frameName: getMetadata(metadata, frame.frameNsId).name,
                    cycleTime: metadata![frame.frameNsId].cycleTime,
                    id: metadata![frame.frameNsId].id,
                    sender: metadata![frame.frameNsId].sender,
                    min: undefined,
                    max: undefined,
                    hex: frame.hex,
                    signalName: undefined,
                    namespace: metadata![frame.frameNsId].namespace,
                    value: undefined,
                    unit: '',
                    frameNsId: frame.frameNsId,
                    frameSignalId: undefined,
                    path: [`${metadata![frame.frameNsId].name}-${frame.timestamp}`], // Hierarchical path for Frame row
                },
                ...frame.signals!.map((signal) => ({
                    type: 'signal',
                    frameNsId: frame.frameNsId,
                    frameSignalId: signal.frameSignalId,
                    frameName: undefined,
                    id: undefined,
                    //id: signal.id,
                    sender: undefined,
                    timestamp: undefined, //frame.timestamp, //frame.timestamp, // Inherit timestamp from Frame
                    relativeTime: undefined, //frame.timestamp - startTimestamp,
                    //name: metadata![frame.frameNsId].name, // Inherit name from Frame
                    namespace: undefined, //metadata![frame.frameNsId].namespace, // Inherit namespace from Frame
                    hex: '', //frame.hex, // Inherit hex from Frame
                    signalName: metadata![frame.frameNsId].signals[signal.frameSignalId].name,
                    value: signal.value,
                    min: metadata![frame.frameNsId].signals[signal.frameSignalId].min,
                    max: metadata![frame.frameNsId].signals[signal.frameSignalId].max,
                    unit: metadata![frame.frameNsId].signals[signal.frameSignalId].unit,
                    cycleTime: undefined, //metadata![frame.frame_ns_id].cycleTime,
                    path: [
                        `${metadata![frame.frameNsId].name}-${frame.timestamp}`,
                        `${metadata![frame.frameNsId].signals[signal.frameSignalId].name}-${frame.timestamp}`,
                    ], // Hierarchical path for Signal row
                    signalNameOptions: metadata![frame.frameNsId].signals[signal.frameSignalId].signalNameOptions,
                })),
            ])

            setRowData(data)
        }
    }, [props.frames])

    const addSignalToChart = (params: any) => {
        props.addSignalFunc(params.node.data as GridRow)
    }

    const getContextMenuItems = (params: any) => {
        const row = params.node.data as GridRow

        if (row.type === 'signal') {
            return [
                {
                    disabled:
                        props.signalsInTimeseriesChart.filter((s) => s.signalEntry.frameSignalId !== row.frameSignalId)
                            .length < props.signalsInTimeseriesChart.length,
                    name: 'Add Signal as timeseries', // Label for the menu item
                    action: () => addSignalToChart(params), // Custom action when clicked
                    icon: '<span class="ag-icon ag-icon-chart"></span>', // Optional icon
                },
                'copy',
                'export', // Include default items like 'Copy', 'Paste'
            ]
        } else {
            return params.defaultItems // Include default items like 'Copy', 'Paste'
        }
    }

    const frameworkComponents = {
        toggleButtonRenderer: ToggleButtonRenderer, // Register the custom renderer
    }

    return (
        <div
            className={`${isDarkMode ? 'ag-theme-balham-dark' : 'ag-theme-balham'} lexend-regular`}
            style={{ height: 500, width: '100%' }}
        >
            <AgGridReact
                rowData={rowData}
                columnDefs={tableHeaders({ startTime: startTimestamp, renderer: 'toggleButtonRenderer' })}
                pagination={true}
                components={frameworkComponents}
                paginationPageSizeSelector={[10, 25, 100, 200]}
                paginationPageSize={100}
                getContextMenuItems={getContextMenuItems}
                // Ensure the grid recalculates after filter changes
                onFilterChanged={(params) => params.api.refreshCells({ force: true })}
                treeData={true}
                sideBar={{
                    toolPanels: [
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel', // Built-in filters panel
                        },
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                            toolPanelParams: {
                                suppressRowGroups: true, // Hide "Row Groups" section
                                suppressValues: true, // Hide "Values" section
                                suppressPivots: true, // Hide "Pivots" section
                                suppressPivotMode: true, // Disable Pivot Mode
                            },
                        },
                    ],
                }}
                columnMenu={'new'}
                getDataPath={(data) => data.path}
                groupDefaultExpanded={-1}
                getRowClass={(params) => {
                    return params.node.data.type === 'frame' ? 'parent-row' : '' // Return 'parent-row' for parent rows
                }}
                onGridReady={onGridReady} // Set up the API reference
                autoGroupColumnDef={{
                    width: 200,
                    headerName: 'Frames', // Column header
                    filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
                    filterParams: {
                        filters: [
                            { filter: 'agNumberColumnFilter' }, // Add number filter
                            { filter: 'agSetColumnFilter' }, // Add set filter
                        ],
                    },
                    cellRendererParams: {
                        suppressCount: true, // Hide the count of child nodes
                        innerRenderer: (params: any) => {
                            // Remove timestamp from group name
                            const lastDashIndex = params.value.lastIndexOf('-')
                            if (lastDashIndex === -1) {
                                // No dash found, return the input as it is
                                return params.value
                            }
                            return params.value.substring(0, lastDashIndex)
                        },
                    },
                }}
                processCellForClipboard={(params: ProcessCellForExportParams) => {
                    if (params.node?.group || params.column.getColId() === 'ag-Grid-AutoColumn') {
                        console.log(params.value)
                        if (`${params.value}`.includes("-")){
                            return params.value.split('-')[0]
                        }
                        return params.value
                    } else {
                        // For other columns, return the raw or formatted value
                        return params.value
                    }
                }}
            />
        </div>
    )
}

export default FrameGrid
