import { Dropdown } from 'react-bootstrap'
import { DropdownIcon } from 'src/assets/Icons'
import SearchInput from 'src/components/SearchInput'
import { SortingType } from '..'

export interface SearchAndSortBarProps {
    setSortingType: (type: SortingType) => void
    sortingType: SortingType
    setSearchKey: (key: string | undefined) => void
    searchKey: string | undefined
    searchStatus: string | undefined
}

export default function SearchAndSortBar(props: SearchAndSortBarProps) {
    const searchOnChange = (setSearchKey: (key: string | undefined) => void, event: any) => {
        const searchKey = event.target.value
        if (searchKey === '') {
            setSearchKey(undefined)
        } else {
            setSearchKey(searchKey)
        }
    }

    const dropdownItem = (sortingType: SortingType) => {
        return (
            <Dropdown.Item className="px-2" onClick={() => props.setSortingType(sortingType)}>
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <p className="m-0 remotive-font-sm">{sortingType}</p>
                </div>
            </Dropdown.Item>
        )
    }

    const component = () => {
        return (
            <>
                <div className="d-flex flex-row align-items-start w-100">
                    <SearchInput
                        placeholder="Filter..."
                        size="sm"
                        position="start"
                        style={{ paddingTop: 2, paddingBottom: 2 }}
                        className="my-0 py-0 mx-2  w-100"
                        searchOnChange={(event: any) => searchOnChange(props.setSearchKey, event)}
                        searchKey={props.searchKey}
                        searchStatus={props.searchStatus}
                    />
                    <Dropdown className="mx-2 mt-2">
                        <Dropdown.Toggle className="w-100 text-start bg-transparent border-0 p-0 text-truncate d-flex justify-content-between me-1">
                            <p className="m-0"></p>
                            <div className="d-flex flex-column align-items-center w-100 remotive-dark-color">
                                <div className="d-flex flex-row justify-content-between align-items-end w-100">
                                    <p className="m-0 remotive-font-sm">{props.sortingType.toString()}</p>
                                    <DropdownIcon sx={{ fontSize: 20 }} />
                                </div>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ minWidth: 120 }} className="remotive-font-md">
                            {dropdownItem(SortingType.UPLOADED_ASC)}
                            {dropdownItem(SortingType.UPLOADED_DESC)}
                            {dropdownItem(SortingType.NAME_ASC)}
                            {dropdownItem(SortingType.NAME_DESC)}
                            {dropdownItem(SortingType.SIZE_ASC)}
                            {dropdownItem(SortingType.SIZE_DESC)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        )
    }

    return component()
}
