import { PreviewRounded } from '@mui/icons-material'
import { FileObject, FileObjectsContainer, Project } from 'src/api/CloudApi/types'
import { getIconBasedOnFileName } from './utilities/FileIcons'
import { formatBytes } from 'src/utils/formatting'
import { useState } from 'react'
import { CopyIcon, DeleteIcon, DownloadIcon } from 'src/assets/Icons'
import CloudApi from 'src/api/CloudApi'
import { toast } from 'react-toastify'
import { formattedToastMessage } from 'src/utils/toast'
import { Form, Spinner } from 'react-bootstrap'

interface FileDetailProps {
    project: Project
    fileObjectsContainer: FileObjectsContainer
    listFilesFunction: (path: string) => void
}

export default function FileDetail(props: FileDetailProps) {
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isCopying, setIsCopying] = useState<boolean>(false)
    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const [filePreviewText, setFilePreviewText] = useState<string>()

    const downloadFile = (fileObject: FileObject, project: Project) => {
        const download = async () => {
            try {
                setIsDownloading(true)
                const downloadUrl = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)
                const fileContents = await fetch(downloadUrl.data)
                const blob = await fileContents.blob()
                // Download file by creating a link, faking a click on it, and then removing the link again
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = fileObject.name
                link.click()
                link.parentNode?.removeChild(link)
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to download selected file, please try again later.')
                )
            }
            setIsDownloading(false)
        }

        download()
    }

    const copyLink = async (fileObjectsContainer: FileObjectsContainer) => {
        setIsCopying(true)
        try {
            navigator.clipboard.writeText(
                `${window.location.origin}/p/${props.project.uid}/files?path=${encodeURI(fileObjectsContainer.path)}`
            )
            await new Promise((r) => setTimeout(r, 500)) // Fake a wait time of 500ms to make it feel like something is happening
            toast.success('Link copied to clipboard')
        } catch (e: any) {
            toast.error('Failed to copy link to clipboard')
        }
        setIsCopying(false)
    }

    const deleteFile = (fileObjectsContainer: FileObjectsContainer, project: Project) => {
        const deleteIt = async () => {
            setIsDeleting(true)
            try {
                const deleteResponse = await CloudApi.deleteStorageFile(project, fileObjectsContainer.path)
                if (deleteResponse.status < 300) {
                    toast.success(`Successfully deleted ${fileObjectsContainer.name}!`)
                    props.listFilesFunction(fileObjectsContainer?.path)
                }
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to delete selected file, please try again later.')
                )
            }
            setIsDeleting(false)
        }

        deleteIt()
    }

    const loadPreview = (fileObject: FileObjectsContainer, project: Project) => {
        const downloadAndShow = async () => {
            try {
                setIsLoadingPreview(true)
                const downloadUrl = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)

                const response = await CloudApi.downloadPreviewContentsFromSignedUrl(downloadUrl.data)

                setFilePreviewText(response.data)
            } catch (e: any) {
                console.error(e)
                toast.error(formattedToastMessage('File error', 'Failed to load preview, please try again later.'))
            }
            setIsLoadingPreview(false)
        }

        downloadAndShow()
    }

    const fileInfoItem = () => {
        return (
            <div className="remotive-primary-10-background rounded-3 p-2">
                <div className="d-flex flex-row justify-content-between flex-wrap">
                    <div className="d-flex flex-row flex-truncate">
                        <div className="d-flex align-items-center mx-2">
                            {getIconBasedOnFileName(props.fileObjectsContainer.name, props.fileObjectsContainer.path, 45)}
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content-start ms-3 text-nowrap text-truncate">
                            <p className="m-0 remotive-font text-wrap text-start text-break" title={props.fileObjectsContainer.path}>
                                {props.fileObjectsContainer.name}
                            </p>
                            <p className="m-0 remotive-font-md remotive-secondary-color text-truncate">
                                {props.fileObjectsContainer.uploaded}
                            </p>
                            <p className="m-0 remotive-font-md remotive-secondary-color text-truncate">
                                {formatBytes(props.fileObjectsContainer.size ?? 0)}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-start pb-1 flex-wrap">
                        <button
                            onClick={() => downloadFile(props.fileObjectsContainer, props.project)}
                            className="btn remotive-btn-md remotive-btn-primary mx-2 d-flex align-items-center text-nowrap mt-2"
                        >
                            {isDownloading ? (
                                <>
                                    <Spinner
                                        size="sm"
                                        style={{ width: 14, height: 14, marginTop: 2, marginBottom: 2 }} // Should add up to 18 px
                                        className="mx-4 remotive-font-sm"
                                    />
                                </>
                            ) : (
                                <>
                                    <DownloadIcon className="me-1" sx={{ fontSize: 15 }} />
                                    <p className="m-0">Download</p>
                                </>
                            )}
                        </button>
                        <button
                            onClick={() => deleteFile(props.fileObjectsContainer, props.project)}
                            className="btn remotive-btn-md remotive-btn-primary mx-2 d-flex align-items-center text-nowrap mt-2"
                        >
                            {isDeleting ? (
                                <>
                                    <Spinner
                                        size="sm"
                                        style={{ width: 14, height: 14, marginTop: 2, marginBottom: 2 }} // Should add up to 18 px
                                        className="mx-4 remotive-font-sm"
                                    />
                                </>
                            ) : (
                                <>
                                    <DeleteIcon className="me-1" sx={{ fontSize: 15 }} />
                                    <p className="m-0">Delete</p>
                                </>
                            )}
                        </button>
                        <button
                            onClick={() => copyLink(props.fileObjectsContainer)}
                            className="btn remotive-btn-md remotive-btn-primary mx-2 d-flex align-items-center text-nowrap mt-2"
                        >
                            {isCopying ? (
                                <>
                                    <Spinner
                                        size="sm"
                                        style={{ width: 14, height: 14, marginTop: 2, marginBottom: 2 }} // Should add up to 18 px
                                        className="mx-4 remotive-font-sm"
                                    />
                                </>
                            ) : (
                                <>
                                    <CopyIcon className="me-1" sx={{ fontSize: 15 }} />
                                    <p className="m-0">Copy link</p>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const loadPreviewButtonOrPreviewText = () => {
        if (filePreviewText !== undefined) {
            return (
                <div className="d-flex flex-grow-1 flex-column">
                    <Form className="w-100 h-100">
                        <Form.Group className="h-100">
                            <Form.Control
                                style={{ resize: 'none' }}
                                as="textarea"
                                readOnly
                                className={
                                    'remotive-font-sm dark-and-light-placeholder h-100 remotive-primary-10-border rounded-3'
                                }
                                defaultValue={filePreviewText}
                            />
                        </Form.Group>
                    </Form>
                </div>
            )
        }
        return (
            <div className="d-flex flex-column justify-content-center flex-grow-1 align-items-center remotive-primary-10-border rounded-3">
                <button
                    onClick={() => loadPreview(props.fileObjectsContainer, props.project)}
                    className="btn remotive-btn remotive-btn-primary m-3 mb-1 flex-grow-0 d-flex align-items-center"
                >
                    {isLoadingPreview ? (
                        <>
                            <Spinner size="sm" className="mx-4" />
                        </>
                    ) : (
                        <>
                            <PreviewRounded sx={{ fontSize: 18 }} className="me-2" />
                            <p className="m-0 remotive-font-sm">Load preview</p>
                        </>
                    )}
                </button>
                <p className={'remotive-font-xs mb-1'}>[Raw contents - max 10kb preview]</p>
            </div>
        )
    }

    return (
        <div className="d-flex flex-column" style={{ height: 500 }}>
            <div className="m-2 mb-0">{fileInfoItem()}</div>
            <div className="m-2 d-flex flex-column flex-grow-1">{loadPreviewButtonOrPreviewText()}</div>
        </div>
    )
}
