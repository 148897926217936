import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import { CreateServiceAccountTokenDTO, Project, AccessToken, ProjectServiceAccount } from '../../api/CloudApi/types'
import { AddIcon, BlockIcon, ErrorIcon } from '../../assets/Icons'
import { toast } from 'react-toastify'
import { formatIso8601TimestampAsYearAndHHmmSS } from '../../utils/time'
import { ZonedDateTime } from '@js-joda/core'
import LoadingContainer from '../LoadingContainer'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'

interface ManageAccessTokenModalProps {
    currentProject: Project | undefined
    show: boolean
    currentServiceAccount: ProjectServiceAccount | undefined
    handleCloseFunction: () => void
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

const MODAL_THEME_BACKGROUND = 'remotive-modal-header-bg'
const MODAL_THEME_COLOR = 'text-light'

export default function ManageAccessTokenModal(props: ManageAccessTokenModalProps) {
    const [accountTokens, setAccountTokens] = useState<Array<AccessToken> | undefined>(undefined)
    const [tokenCreationState, setTokenCreationState] = useState<'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR'>(
        'NOT_STARTED'
    )

    useEffect(() => {
        console.debug('Mounted manage access token modal!')
    }, [])

    useEffect(() => {
        if (props.show) {
            const serviceAccount = props.currentServiceAccount
            if (serviceAccount !== undefined && props.currentProject?.uid !== undefined) {
                console.log(`Fetching tokens as service account ${serviceAccount.name}`)
                setTokenStateAsServiceAccount(props.currentProject.uid, serviceAccount)
            } else {
                console.log(`Fetching tokens as user`)
                setTokenStateAsPersonalAccount()
            }
        }
    }, [props.show, props.currentProject])

    const setTokenStateAsServiceAccount = async (projectUid: string, projectServiceAccount: ProjectServiceAccount) => {
        try {
            const tokensResponse = await CloudApi.listProjectServiceAccountAccessTokens(
                projectUid,
                projectServiceAccount
            )
            setTokens(
                (await tokensResponse.data).map((token) => {
                    return {
                        creator: token.creator,
                        created: token.created,
                        name: token.name,
                        revoked: token.revoked,
                        expires: token.expires,
                    } as AccessToken
                })
            )
        } catch (e) {
            toast.error('Something went wrong when fetching service account access tokens, please try again later')
        }
    }

    const setTokenStateAsPersonalAccount = async () => {
        const tokensResponse = await CloudApi.listPersonalAccessTokens()
        setTokens(
            (await tokensResponse.data).map((token) => {
                return {
                    creator: 'You',
                    created: token.created,
                    name: token.name,
                    revoked: token.revoked,
                    expires: token.expires,
                } as AccessToken
            })
        )
    }

    const setTokens = (tokens: Array<AccessToken>) => {
        setAccountTokens(
            tokens.sort(
                (a, b) =>
                    ZonedDateTime.parse(a.created).toEpochSecond() - ZonedDateTime.parse(b.created).toEpochSecond()
            )
        )
    }

    const createAccessToken = () => {
        if (props.currentServiceAccount !== undefined) {
            console.log('Creating access token as a service account')
            createServiceAccountAccessToken()
        } else {
            console.log('Creating access token as a personal account')
            createPersonalAccessToken()
        }
    }

    const createPersonalAccessToken = async () => {
        setTokenCreationState('IN_PROGRESS')
        try {
            const response = await CloudApi.createPersonalAccessToken()
            const accessTokenCreated = await response.data

            const fileName = `personal-token-${accessTokenCreated.name}`
            const fileContents = JSON.stringify(accessTokenCreated)

            downloadFile(fileName, fileContents)

            toast.success(
                `Successfully created a new access token ${accessTokenCreated.name} for your personal account.`
            )
            await setTokenStateAsPersonalAccount()
            setTokenCreationState('DONE')
        } catch (err) {
            setTokenCreationState('ERROR')
            toast.error('Failed to create personal access token.')
        }
    }

    const createServiceAccountAccessToken = async () => {
        const projectUid = props.currentProject?.uid
        const currentAccount = props.currentServiceAccount
        if (projectUid && currentAccount) {
            setTokenCreationState('IN_PROGRESS')
            const dto = { daysUntilExpiry: 365 } as CreateServiceAccountTokenDTO
            try {
                const response = await CloudApi.createServiceAccountAccessToken(projectUid, currentAccount, dto)
                const accessTokenCreated = await response.data

                const fileName = `service-account-token-${currentAccount.name}-${accessTokenCreated.name}`
                const fileContents = JSON.stringify(accessTokenCreated)

                downloadFile(fileName, fileContents)

                toast.success(
                    `Successfully created a new access token (${accessTokenCreated.name}) for ${currentAccount.name}.`
                )
                await setTokenStateAsServiceAccount(projectUid, currentAccount)
                setTokenCreationState('DONE')
            } catch (err) {
                setTokenCreationState('ERROR')
                toast.error('Failed to create service account access token')
            }
        }
    }

    const downloadFile = (fileName: string, fileContents: string) => {
        // Download file by creating a link, faking a click on it, and then removing the link again
        var link = document.createElement('a')
        link.download = `${fileName}.json`
        var blob = new Blob([fileContents], { type: 'text/plain' })
        link.href = window.URL.createObjectURL(blob)
        link.click()
    }

    const revokeAccessToken = async (accessToken: AccessToken) => {
        const projectUid = props.currentProject?.uid
        const isServiceAccount = props.currentServiceAccount !== undefined
        try {
            if (isServiceAccount && projectUid) {
                const currentAccount = props.currentServiceAccount!
                await CloudApi.revokeServiceAccountAccessToken(projectUid, currentAccount, accessToken.name)
                toast.success(`Successfully revoked ${accessToken.name}`)
                await setTokenStateAsServiceAccount(projectUid, currentAccount)
            } else {
                await CloudApi.revokePersonalAccessToken(accessToken as AccessToken)
                toast.success(`Successfully revoked ${accessToken.name}`)
                await setTokenStateAsPersonalAccount()
            }
        } catch (error) {
            return toast.error(`Failed to revoke ${accessToken.name}`)
        }
    }

    const listTokens = () => {
        if (accountTokens !== undefined) {
            const tokenItems = accountTokens.map((accountToken) => {
                return (
                    <div key={accountToken.name} className="my-1">
                        <div className="remotive-primary-10-background p-1 rounded d-flex flex-row">
                            {/*Name*/}
                            <div className="col-3 d-flex flex-column">
                                <p className="m-0 ms-1 text-truncate remotive-font-md">
                                    <b>{accountToken.name}</b>
                                </p>
                            </div>
                            <div className="col-4 d-flex flex-column">
                                <p className="m-0 remotive-font-md">Created by</p>
                                <p className="m-0 remotive-secondary-color me-1 remotive-font-sm text-truncate">{`${
                                    accountToken.creator
                                } on ${formatIso8601TimestampAsYearAndHHmmSS(accountToken.created)}`}</p>
                            </div>
                            <div className="col-3 d-flex flex-column">
                                <p className="m-0 remotive-font-md">Expires</p>
                                <p className="m-0 remotive-secondary-color remotive-font-sm">{accountToken.expires}</p>
                            </div>
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                {accountToken.revoked === true ? (
                                    <>
                                        <ErrorIcon className="me-1  text-danger" sx={{ fontSize: 17 }} />
                                        <p className="m-0 me-3 remotive-font-sm text-truncate">Revoked</p>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            onClick={() =>
                                                props.openConfirmationDialog({
                                                    dialogTitle: 'Are you sure?',
                                                    bodyText: (
                                                        <>
                                                            Are you sure you want to revoke the access token{' '}
                                                            <b>{accountToken.name}</b>?
                                                        </>
                                                    ),
                                                    cancelButtonText: 'No, cancel',
                                                    confirmButtonText: 'Yes, revoke it',
                                                    bodySubtitle: 'Once a token is revoked it can not be used anymore.',
                                                    handleCancelFunction: () =>
                                                        console.log('User cancelled confirmation dialog'),
                                                    handleConfirmFunction: () => revokeAccessToken(accountToken),
                                                } as ConfirmDialogProperties)
                                            }
                                            className="btn remotive-btn-sm remotive-btn-primary d-flex align-items-center"
                                        >
                                            <BlockIcon sx={{ fontSize: 15 }} />
                                            <p className="d-none d-md-block m-0 ms-1 remotive-font-sm">Revoke</p>
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })
            const addTokenButtonItem = (
                <div key={'ADD_TOKEN_BUTTON_ITEM_KEY'}>
                    <div
                        className="rounded d-flex flex-row justify-content-center align-items-center remotive-primary-40-border remotive-primary-0-background "
                        style={{ minHeight: '47px', borderStyle: 'dashed', borderWidth: '2px' }}
                    >
                        <button
                            className="h-100 w-100 d-flex flex-column align-items-center btn remotive-btn-no-bg"
                            onClick={() => createAccessToken()}
                        >
                            <div className="d-flex flex-row align-items-center">
                                <AddIcon className="me-1" sx={{ fontSize: 20 }} />
                                <p className="m-0 remotive-font-md">
                                    <b>Create new token</b>
                                </p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <p className="m-0 remotive-font-xxs remotive-secondary-color">
                                    You will be prompted to download a token file, this is only possible once per access
                                    token.
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
            )
            tokenItems.push(addTokenButtonItem)
            return tokenItems
        }
        return <>N/A</>
    }

    const closeModal = () => {
        setAccountTokens(undefined)
        setTokenCreationState('NOT_STARTED')
        props.handleCloseFunction()
    }

    const getModalContent = () => {
        const title = 'Manage tokens'
        switch (tokenCreationState) {
            case 'IN_PROGRESS':
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Creating token...</p>
                                <Spinner className="remotive-primary-50-color" size="sm" />
                            </div>
                        </Modal.Body>
                    </>
                )

            case 'ERROR':
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-3 mt-3 text-center">
                                <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                                <p className="fs-5 m-0">There was an issue creating your token...</p>
                                <p className="remotive-font-sm remotive-secondary-color" style={{ marginTop: '-5px' }}>
                                    Please try again later
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="py-1 d-flex align-items-center justify-content-center m-2 mb-4">
                                <ErrorIcon sx={{ fontSize: 21 }} className="me-1 text-danger" />
                                <p className="m-0 remotive-font-md">
                                    {props.currentServiceAccount !== undefined ? 'Service account' : 'Personal'} access
                                    tokens are a security risk if they aren't managed correctly!
                                </p>
                            </div>
                            {accountTokens === undefined ? (
                                <>
                                    <LoadingContainer spinnerSize="sm" />
                                </>
                            ) : (
                                <>{listTokens()}</>
                            )}
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal size="lg" show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
