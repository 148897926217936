import { useEffect } from 'react'
import { useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { Modal, Form, InputGroup, Row, Button, Dropdown, Spinner } from 'react-bootstrap'
import { AutomatchDetail, Project, RecordingFile, RecordingSession, SignalDatabase } from '../../../../api/CloudApi/types'
import CloudApi from '../../../../api/CloudApi'
import { toast } from 'react-toastify'
import LoadingContainer from '../../../../components/LoadingContainer'
import { DropdownIcon, WarningIcon } from '../../../../assets/Icons'
import InfoBox from '../../../../components/messageBox/InfoBox'
import { formattedToastMessage } from '../../../../utils/toast'
import { deletePlaybackBrokerUrl } from '../../../../utils/broker'
import { calculatePercentage } from '../../../../utils/numberFormatting'
import {
    ProductAnalyticsContext,
    ProductAnalyticsProperties,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../../../../utils/ProductAnalytics'
import { use } from '@js-joda/core'
import { AxiosError } from 'axios'

interface EditRecordingConfigurationModalProps {
    show: boolean
    handleCloseFunction: () => void
    currentRecordingFile: RecordingFile | undefined
    currentRecordingSession: RecordingSession | undefined
    availableSignalDatabases: Array<SignalDatabase>
    allNamespaces: Array<string>
    currentProject: Project | undefined
    analyticsProperties?: ProductAnalyticsProperties
    refreshRecordingSession: Function
}

const MODAL_THEME_BACKGROUND = 'remotive-modal-header-bg'
const MODAL_THEME_COLOR = 'text-light'

const AnalyticsActions = {
    CHANGE_SIGNAL_DATABASE: createAnalyticsTrackingKey(
        ProductAnalyticsContext.RD_VISUALIZE_TAB,
        'ChangeSignalDatabase'
    ),
    FIND_MATCHES: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_VISUALIZE_TAB, 'FindAutomatchMatches'),
}

enum ComponentState {
    LOADING,
    DONE,
}

export default function EditRecordingConfigurationModal(props: EditRecordingConfigurationModalProps) {
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DONE)
    const [currentFileName, setCurrentFileName] = useState<string>()
    const [currentNamespace, setCurrentNamespace] = useState<string>()
    const [currentSignalDatabaseName, setCurrentSignalDatabaseName] = useState<string>()
    const [automatchDetails, setAutomatchDetails] = useState<Array<AutomatchDetail>>([])
    const [namespacesInUse, setNamespacesInUse] = useState<Array<string>>([])

    const [isFindingMatches, setIsFindingMatches] = useState<boolean>(false)
    const [hasFindMatchesBeenRun, setHasFindMatchesBeenRun] = useState<boolean>(false)

    const [isSignalDatabasOpen, setIsSignalDatabaseOpen] = useState(false)
    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.analyticsProperties?.currentUser,
        billableUnit: props.analyticsProperties?.currentBillableUnit,
    } as ProductAnalyticsProps)

    const toggleDropdown = () => setIsSignalDatabaseOpen(!isSignalDatabasOpen)

    useEffect(() => {
        console.log(props.show)
        if (props.show) {
            setCurrentFileName(props.currentRecordingFile?.fileName)
            setCurrentNamespace(props.currentRecordingFile?.metadata?.namespace)
            setCurrentSignalDatabaseName(props.currentRecordingFile?.metadata?.databaseDetails?.name)
            setNamespacesInUse(
                props.allNamespaces.filter((it) => it !== props.currentRecordingFile?.metadata?.namespace)
            )

            if (props.currentRecordingFile?.metadata?.databaseDetails?.name === undefined) {
                setCurrentSignalDatabaseName(props.currentRecordingFile?.metadata?.automatchDetails?.database)
            }

            if (props.currentRecordingFile?.metadata?.automatchDetails !== undefined) {
                setAutomatchDetails([
                    {
                        matchingFrameIds: props.currentRecordingFile.metadata.automatchDetails.matchingFrameIds,
                        databaseName: props.currentRecordingFile.metadata.automatchDetails.database,
                        totalFrameIds: props.currentRecordingFile.metadata.totalFrameIds,
                    } as AutomatchDetail,
                ])
            }
        }
    }, [props.show, props.currentRecordingFile])

    useEffect(() => {
        if (isFindingMatches) {
            setHasFindMatchesBeenRun(true)
        }
    }, [isFindingMatches])

    const closeModal = async () => {
        props.handleCloseFunction()
        await new Promise((r) => setTimeout(r, 300))
        setIsSignalDatabaseOpen(false)
        setHasFindMatchesBeenRun(false)
        setAutomatchDetails([])
        setCurrentNamespace(props.currentRecordingFile?.metadata?.namespace)
        setCurrentSignalDatabaseName(props.currentRecordingFile?.metadata?.databaseDetails?.name)
        setComponentState(ComponentState.DONE)
    }

    const saveAndClose = () => {
        const awaitableRequest = async () => {
            if (
                props.currentRecordingFile &&
                props.currentRecordingSession &&
                props.currentProject &&
                currentNamespace &&
                currentSignalDatabaseName
            ) {
                setComponentState(ComponentState.LOADING)
                if (currentSignalDatabaseName !== props.currentRecordingFile.metadata?.databaseDetails?.name) {
                    productAnalyticsClient.track(AnalyticsActions.CHANGE_SIGNAL_DATABASE)
                }
                try {
                    await CloudApi.updateRecordingFileConfiguration(
                        props.currentProject.uid,
                        props.currentRecordingSession.sessionId,
                        props.currentRecordingFile.fileName,
                        currentNamespace,
                        currentSignalDatabaseName
                    )
                    await props.refreshRecordingSession()
                    toast.success(
                        formattedToastMessage(
                            'Updated',
                            `The recording file configuration for ${currentFileName} has been updated`
                        )
                    )
                    deletePlaybackBrokerUrl()
                } catch (e: any) {
                    toast.error(formattedToastMessage('Failed to save', e.response?.statusText ?? 'Unknown error'))
                }
            } else {
                toast.error(
                    formattedToastMessage(
                        'Failed to save',
                        'Something went wrong when trying to save the recording file configuration. Please refresh the application and try again.'
                    )
                )
            }
            await closeModal()
        }

        awaitableRequest()
    }

    const findSignalDatabaseMatch = async () => {
        if (
            props.currentProject?.uid &&
            props.currentRecordingSession?.sessionId &&
            props.currentRecordingFile?.fileName
        ) {
            setIsFindingMatches(true)
            productAnalyticsClient.track(AnalyticsActions.FIND_MATCHES)
            try {
                const automatchDetails = await CloudApi.getRecordingFileAutomatchDetails(
                    props.currentProject?.uid,
                    props.currentRecordingSession?.sessionId,
                    props.currentRecordingFile?.fileName
                )
                setAutomatchDetails(automatchDetails.data)
                if (automatchDetails.data.length === 0) {
                    toast.warn(
                        formattedToastMessage('No matches found', 'We could not find any matching signal databases.')
                    )
                } else if (automatchDetails.data.length === 1) {
                    setIsSignalDatabaseOpen(true)
                    toast.success(
                        formattedToastMessage(
                            'Only 1 match found',
                            'We could only find a single matching database file. For better results, upload more signal databases.'
                        )
                    )
                } else {
                    setIsSignalDatabaseOpen(true)
                    toast.success(
                        formattedToastMessage(
                            `${automatchDetails.data.length} matches found!`,
                            'We found matching signal databases and added the matching details to the signal database dropdown menu.'
                        )
                    )
                }
            } catch (err: any) {
                console.error(err)
                const errorMessageToDisplay =
                    (err instanceof AxiosError && err.response?.data) ||
                    'We encountered an error when trying to find matching database files.'
                setHasFindMatchesBeenRun(false)
                toast.error(formattedToastMessage('Matching error', errorMessageToDisplay))
            }
            setIsFindingMatches(false)
        } else {
            toast.error(
                formattedToastMessage(
                    'Matching error',
                    'Could not find matching database files due to a parameter error. Please refresh the page and try again.'
                )
            )
        }
    }

    const getForm = () => {
        const schema = object().shape({
            namespace: string()
                .required('A namespace is required')
                .max(50, 'The namespace is too long!')
                .min(2, 'The namespace should be at least 2 characters long.')
                .test(
                    'isAllowedNamespace',
                    'This namespace is already in use by another recording file.',
                    (value) => !namespacesInUse.includes(value)
                ),
        })
        const noSignalDatabasesAvailable = props.availableSignalDatabases.length === 0
        return (
            <Formik
                // After an error this is used to fake that these values has already been "touched"
                initialTouched={{
                    namespace: currentNamespace !== undefined,
                }}
                validationSchema={schema}
                initialValues={{
                    namespace: currentNamespace,
                }}
                onSubmit={() => console.log()}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className={'mb-5'}>
                            <InfoBox
                                message={
                                    <>
                                        You are editing the file <b>{currentFileName}</b> in the recording session{' '}
                                        {props.currentRecordingSession?.displayName ?? 'N/A'}
                                    </>
                                }
                            />
                            <div>
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-row justify-content-between">
                                        <div>
                                            <p className={`remotive-font-sm m-0 ms-1`}>Signal database</p>
                                            <p className={`remotive-font-xs remotive-secondary-color m-0 ms-1`}>
                                                The signal database is used to decode raw signal data.
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                disabled={isFindingMatches}
                                                onClick={() => findSignalDatabaseMatch()}
                                                style={{ minWidth: 105 }}
                                                className="btn remotive-btn-primary remotive-btn-sm"
                                            >
                                                {isFindingMatches ? (
                                                    <Spinner size="sm" style={{ height: 10, width: 10 }} />
                                                ) : (
                                                    'Find matches'
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                    <Dropdown
                                        className="rounded-0 w-100 flex-truncate"
                                        show={isSignalDatabasOpen}
                                        onToggle={toggleDropdown}
                                    >
                                        <Dropdown.Toggle
                                            disabled={noSignalDatabasesAvailable || isFindingMatches}
                                            className="text-start bg-transparent border-1 remotive-primary-30-border p-0 text-truncate d-flex justify-content-between w-100"
                                        >
                                            <div className="d-flex flex-row justify-content-between w-100">
                                                <p className="text-truncate remotive-dark-color m-0 ms-2 my-1 remotive-font-md">
                                                    {currentlySelectedSignalDatabaseLabel(
                                                        noSignalDatabasesAvailable,
                                                        isFindingMatches,
                                                        props.availableSignalDatabases,
                                                        currentSignalDatabaseName
                                                    )}
                                                </p>
                                                <DropdownIcon className="remotive-dark-color" sx={{ fontSize: 20 }} />
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            style={{
                                                minWidth: 100,
                                                maxWidth: '100%',
                                                maxHeight: '30vh',
                                                overflowY: 'auto',
                                            }}
                                            className={`remotive-font-md p-1 px-0 position-fixed flex-truncate`}
                                        >
                                            {props.availableSignalDatabases
                                                .map((it) => convertToSortableSignalDatabaseLabel(it))
                                                .sort((a, b) => (b.matchPercentage ?? 0) - (a.matchPercentage ?? 0))
                                                .map((it) => {
                                                    return (
                                                        <Dropdown.Item
                                                            as={Button}
                                                            className="remotive-font-md w-100 text-truncate"
                                                            onClick={() =>
                                                                setCurrentSignalDatabaseName(it.signalDatabase.name)
                                                            }
                                                            key={it.signalDatabase.name}
                                                        >
                                                            {it.label}
                                                        </Dropdown.Item>
                                                    )
                                                })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <Form.Group as={Row} className="my-2">
                                <InputGroup size="sm" hasValidation={true}>
                                    <div className="d-flex flex-column w-100">
                                        <p className={`remotive-font-sm m-0 ms-1`}>Namespace</p>
                                        <p className={`remotive-font-xs remotive-secondary-color m-0 ms-1`}>
                                            The namespace is the relation between the signals in this file and the
                                            signal database file.
                                        </p>
                                        <Form.Control
                                            placeholder={'Namespace...'}
                                            type="text"
                                            className="remotive-font-md rounded border-1 remotive-primary-30-border dark-and-light-placeholder"
                                            onBlur={handleBlur}
                                            name={'namespace'}
                                            value={currentNamespace}
                                            onChange={(it: any) => {
                                                setCurrentNamespace(it.target.value)
                                                handleChange(it)
                                            }}
                                            isValid={(!errors.namespace as boolean) && (touched.namespace as boolean)}
                                            isInvalid={errors.namespace !== undefined}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <p className="remotive-font-xs text-danger-subtle m-0 p-1 pt-0">
                                                <>{errors.namespace}</>
                                            </p>
                                        </Form.Control.Feedback>
                                    </div>
                                </InputGroup>
                            </Form.Group>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button
                                className="btn remotive-btn remotive-btn remotive-btn-primary mx-3"
                                onClick={() => closeModal()}
                            >
                                <div className="d-flex align-items-center text-light">
                                    <p className="text-light m-0">Cancel</p>
                                </div>
                            </Button>
                            <Button
                                className="btn remotive-btn remotive-btn remotive-btn-success mx-3"
                                disabled={currentSignalDatabaseName === null || values.namespace === '' || !isValid}
                                onClick={() => saveAndClose()}
                            >
                                <div className="d-flex align-items-center text-light">
                                    <p className="text-light m-0">Save</p>
                                </div>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }

    const currentlySelectedSignalDatabaseLabel = (
        isNoSignalDatabasesAvailable: boolean,
        isFindingMatches: boolean,
        availableSignalDatabases: Array<SignalDatabase>,
        currentSignalDatabaseName: string | undefined
    ) => {
        if (isNoSignalDatabasesAvailable) {
            return (
                <>
                    <WarningIcon className="mb-1 text-warning me-1" sx={{ fontSize: 17 }} />
                    There are no signal databases in this project
                </>
            )
        }
        if (isFindingMatches) {
            return 'Finding matching signal databases...'
        }
        const selectedSignalDatabase = availableSignalDatabases.find((it) => it.name === currentSignalDatabaseName)
        if (selectedSignalDatabase !== undefined) {
            return convertToSortableSignalDatabaseLabel(selectedSignalDatabase).label
        }

        return 'Select file...'
    }

    const convertToSortableSignalDatabaseLabel = (signalDatabase: SignalDatabase) => {
        const automatchDetail = automatchDetails.find((detail) => detail.databaseName === signalDatabase.name)
        const matchPercentage =
            automatchDetail === undefined
                ? undefined
                : calculatePercentage(automatchDetail.matchingFrameIds, automatchDetail.totalFrameIds)
        return {
            signalDatabase: signalDatabase,
            label: signaldatabaseWithAutomatchLabel(signalDatabase.name, matchPercentage),
            matchPercentage,
        }
    }

    const signaldatabaseWithAutomatchLabel = (signalDatabaseName: string, matchPercentage: number | undefined) => {
        if (matchPercentage === undefined) {
            if (hasFindMatchesBeenRun) {
                return `Unknown match - ${signalDatabaseName}`
            }
            return signalDatabaseName
        }
        return `${matchPercentage}% match - ${signalDatabaseName}`
    }

    const getModalContent = () => {
        const title = 'Edit recording file'
        switch (componentState) {
            case ComponentState.LOADING:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <LoadingContainer loadingText="Updating recording file configuration..." spinnerSize="sm" />
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">{getForm()}</Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal show={props.show && props.currentRecordingFile !== undefined} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
