import { ProgressBar, Spinner } from 'react-bootstrap'
import { CancelRounded, SuccessIcon } from '../assets/Icons'
import { formatBytes } from '../utils/formatting'
import { UploadInProgress } from '../pages/Storage'
import { CloseRounded, HighlightOffRounded } from '@mui/icons-material'

interface MultipleInlineFileUploadProgressContainerProps {
    inProgressText: string
    finishedText: string
    uploadInProgress: UploadInProgress
}

export default function MultipleInlineFileUploadProgressContainer(
    props: MultipleInlineFileUploadProgressContainerProps
) {
    const fileUploadProgressContainer = () => {
        return (
            <div className="d-flex flex-column justify-content-center rounded-3 remotive-primary-10-background h-100 w-100 p-2 my-1">
                {props.uploadInProgress.percent < 100 && (
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="ms-2 me-4">
                            <Spinner
                                size="sm"
                                className={`remotive-primary-50-color`}
                                style={{ height: 22, width: 22 }}
                            />
                        </div>
                        <div className="d-flex flex-grow-1 flex-column">
                            <div className="d-flex align-items-center text-start mb-2">
                                <button
                                    title={'Cancel upload'}
                                    className="btn m-0 p-0 remotive-btn-no-bg remotive-btn-sm me-4"
                                    onClick={() => {
                                        props.uploadInProgress.abortController.abort()
                                        props.uploadInProgress.percent = 100
                                    }}
                                >
                                    <HighlightOffRounded sx={{ fontSize: 20 }} />
                                </button>

                                <p className={`remotive-font-md m-0 me-2`}>
                                    {props.uploadInProgress.rate
                                        ? `${props.inProgressText} - ${formatBytes(props.uploadInProgress.rate)}/s`
                                        : props.inProgressText}
                                </p>
                            </div>
                            <ProgressBar
                                className={`remotive-primary-10-color`}
                                label={`${props.uploadInProgress.percent}%`}
                                now={props.uploadInProgress.percent}
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return fileUploadProgressContainer()
}
