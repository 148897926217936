import { DateTimeFormatter, Instant, ZonedDateTime, ZoneId } from '@js-joda/core'

const dayOfWeekShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const epochInMicrosToZonedDateTime = (epochInMicros: number): ZonedDateTime => {
    const instant = Instant.ofEpochMicro(epochInMicros)
    const zoneId = ZoneId.of('UTC')
    const zonedDateTime = ZonedDateTime.ofInstant(instant, zoneId)
    return zonedDateTime
}

export const epochInMicrosToDate = (micros: number): string => {
    const zdt = ZonedDateTime.ofInstant(Instant.ofEpochMicro(micros), ZoneId.of('UTC'))

    return `${dayOfWeekShort[zdt.dayOfWeek().value() % 7]}, ${zdt.dayOfMonth()} ${
        shortMonthNames[zdt.monthValue()]
    } ${zdt.format(DateTimeFormatter.ofPattern('yyyy'))}`
}

export const epochInMicrosToHHmmss = (micros: number, microPrecisionInDecimals: boolean = false): string => {
    const zdt = ZonedDateTime.ofInstant(Instant.ofEpochMicro(micros), ZoneId.of('UTC'))
    if (microPrecisionInDecimals) {
        return zdt.format(DateTimeFormatter.ofPattern('HH:mm:ss.SSSSSS'))
    }
    return zdt.format(DateTimeFormatter.ofPattern('HH:mm:ss.SSS'))
}

export const zonedDateTimeToEpochInMicros = (zdt: ZonedDateTime) => {
    return zdt.toInstant().epochSecond() * 1_000_000 + Math.floor(zdt.toInstant().nano() / 1_000)
}
