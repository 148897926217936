import { ColDef, ValueGetterParams } from 'ag-grid-community'
import { ValueFormatterParams } from 'ag-grid-enterprise'
import { GridRow } from './types'
import { SignalNameOption } from '../../../../../api/CloudApi/types'
import { microsToSeconds, epochInMicrosToZonedDateTime } from 'src/utils/time'

interface ColumnProperties {
    startTime: number
    renderer: string
}

export function tableHeaders(props: ColumnProperties): ColDef[] {
    return [
        {
            headerName: 'Timestamp',
            colId: 'timestamp',
            field: 'timestamp',
            sortable: true,
            filter: 'agNumberColumnFilter',
            sort: 'asc',
            minWidth: 200,
            valueFormatter: (params: ValueFormatterParams<GridRow, any>) => {
                if (!params.data?.timestamp) return '' // Handle undefined or null timestamp

                const micros = params.data.timestamp as number // Assume input is in microseconds
                return `${epochInMicrosToZonedDateTime(micros).toOffsetDateTime()}`
            },
        },
        {
            headerName: 'Relative time',
            colId: 'relativeTime',
            field: 'relativeTime',
            sortable: false,
            width: 130,
            valueFormatter: (params: ValueFormatterParams<GridRow>) => {
                if (!params.data?.timestamp) return '' // Handle undefined or null timestamp

                const micros = params.data.timestamp as number // Assume input is in microseconds
                return `${microsToSeconds(micros - props.startTime).toFixed(6)}s`
            },
        },
        /*{
            field: 'active',
            headerName: 'Active',
            cellRenderer: props.renderer, // Reference the custom renderer
            sortable: false,
            filter: false,
        },*/
        {
            headerName: 'Time diff',
            field: 'diff',
            sortable: false, // Filter and sort is not possible since its dynamic   ally created
            width: 100,
            valueGetter: (params: ValueGetterParams<GridRow>) => {
                if (!params.data || !params.node) return null
                const currentRowIndex = params.node.rowIndex
                if (currentRowIndex === undefined || currentRowIndex === null) {
                    return null
                }
                // Skip calculation if the current row is not a parent
                if (params.data.type === 'signal') return null

                // Find the previous parent row
                for (let i = currentRowIndex - 1; i >= 0; i--) {
                    const previousRowNode = params.api.getDisplayedRowAtIndex(i)
                    if (previousRowNode && previousRowNode.data && previousRowNode.data.type !== 'signal') {
                        const currentTimestampMicros = params.data.timestamp!
                        const previousTimestampMicros = previousRowNode.data.timestamp!

                        // Calculate the time difference in microseconds
                        const timeDifferenceMicros = currentTimestampMicros - previousTimestampMicros

                        // Convert to seconds with microsecond precision
                        const timeDifferenceSeconds = timeDifferenceMicros / 1_000_000

                        // Return the formatted string in seconds
                        return `${timeDifferenceSeconds.toFixed(6)}s`
                    }
                }

                // No previous parent row found
                return 'N/A'
            },
        },
        {
            headerName: 'FrameID',
            field: 'id',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
            cellRenderer: (params: any) =>
                !params.value ? null : `  ${params.value}  / 0x${params.value.toString(16).toUpperCase()}`,

            width: 120,
        },
        {
            headerName: 'Data',
            field: 'hex',
            sortable: false,
            filter: true,
            minWidth: 90,
            valueFormatter: (params) => {
                const hex: string = params.value || ''
                return (
                    hex
                        .toUpperCase()
                        .match(/.{1,2}/g)
                        ?.join(' ') || ''
                ) // Add spaces every 2 characters
            },
        },
        {
            headerName: 'Value',
            field: 'value',
            sortable: true,
            minWidth: 90,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.signalNameOptions?.length > 0) {
                    const humanReadableName = params.data.signalNameOptions.find(
                        (option: SignalNameOption) => option.value === params.data.value
                    )?.name
                    if (humanReadableName !== undefined) {
                        return `${humanReadableName} (${params.data.value})`
                    }
                    return params.data.value
                }
                return params.data.value
            },
        },
        {
            headerName: 'Channel',
            field: 'namespace',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
        {
            headerName: 'Sender',
            field: 'sender',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agTextColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },

        {
            headerName: 'Cycle time',
            field: 'cycleTime',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
        {
            headerName: 'Unit',
            field: 'unit',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agTextColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
        {
            headerName: 'Min',
            field: 'min',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
        {
            headerName: 'Max',
            field: 'max',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
    ]
}
