import { RecordingAnnotation } from "../../../api/CloudApi/types"
import { addDurationToIso8061Timestamp, formatIso8061TimestampAsMonthWithHHMMSS, formatIso8601TimestampAsHHMMSSmmm } from "../../../utils/time"

export const getAnnotationCreatedByLabel = (annotation: RecordingAnnotation) => {
    return `By ${annotation.createdBy.displayName} on ${formatIso8061TimestampAsMonthWithHHMMSS(
        annotation.created
    )}`
}

export const getAnnotationTimestampRelativeToGraph = (annotation: RecordingAnnotation) => {
    return annotation.duration !== undefined
        ? `In graph from ${formatIso8601TimestampAsHHMMSSmmm(annotation.timestamp)} to ${formatIso8601TimestampAsHHMMSSmmm(
              addDurationToIso8061Timestamp(annotation.timestamp, annotation.duration)
          )}`
        : `In graph at ${formatIso8601TimestampAsHHMMSSmmm(annotation.timestamp)}`
}