import { ChronoUnit, DateTimeFormatter, Duration, ZonedDateTime } from '@js-joda/core'

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export const iso8601TimestampAsZonedDateTime = (iso8601Timestamp: string) => {
    return ZonedDateTime.parse(iso8601Timestamp)
}

function _getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th' // Handles 11th, 12th, 13th
    switch (day % 10) {
        case 1:
            return 'st'
        case 2:
            return 'nd'
        case 3:
            return 'rd'
        default:
            return 'th'
    }
}

export function addDurationToIso8061Timestamp(iso8601Timestamp: string, iso8061Duration: string) {
    const duration = Duration.parse(iso8061Duration).toNanos()
    const zdt = iso8601TimestampAsZonedDateTime(iso8601Timestamp)
    return zdt.plus(duration, ChronoUnit.NANOS).toString()
}

export function formatIso8601TimestampAsYearAndHHmmSS(iso8601Timestamp: string): string {
    const commentDate = new Date(iso8601Timestamp)
    if (isNaN(commentDate.getTime())) {
        return 'unrecognizable date'
    }
    
    const zdt = iso8601TimestampAsZonedDateTime(iso8601Timestamp)
    return zdt.format(DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'))
}

export function formatIso8601TimestampAsHHMMSSmmm(iso8601Timestamp: string): string {
    const commentDate = new Date(iso8601Timestamp)
    if (isNaN(commentDate.getTime())) {
        return 'unrecognizable date'
    }

    const zdt = iso8601TimestampAsZonedDateTime(iso8601Timestamp)
    return `${zdt.format(DateTimeFormatter.ofPattern('HH:mm:ss.SSS'))}`
}

export function formatIso8061TimestampAsMonthWithHHMMSS(iso8601Timestamp: string): string {
    const commentDate = new Date(iso8601Timestamp)
    if (isNaN(commentDate.getTime())) {
        return 'unrecognizable date'
    }

    const day = commentDate.getUTCDate() // Get the day of the month
    const dayWithSuffix = day + _getOrdinalSuffix(day) // Append the correct suffix
    const month = monthNames[commentDate.getUTCMonth()] // Get the month name

    const zdt = iso8601TimestampAsZonedDateTime(iso8601Timestamp)
    return `${month} ${dayWithSuffix}, ${zdt.format(DateTimeFormatter.ofPattern('HH:mm:ss'))}`
}
